import React, { useRef, useState } from 'react'
import FSLogo from '../../assets/img/PBLogo.png'
import GLogo from '../../assets/img/google_icon.png'
import MLogo from '../../assets/img/microsoft_logo.png'
import alertify from 'alertifyjs'
import { signInWithCustomToken } from 'firebase/auth'

const Login = (props) => {
  const [withGoogle, setWithGoogle] = useState(true)
  const withMicrosoft = useRef(window.location.origin.includes('nerds') ? true : false)

  return (
    <div className='flex h-screen bg-black'>
      <button className='h-8 w-8 absolute top-0 right-0 focus:outline-none' onClick={() => setWithGoogle((withGoogle) => !withGoogle)} />
      <div className='m-auto'>
        <img className='h-24 w-auto mb-5' src={FSLogo} alt='FSLogo' />
        {withMicrosoft.current ? <SignInMicrosoft auth={props.auth} microsoftLogin={props.microsoftLogin} /> : ''}
        {withGoogle ? <SignInGoogle auth={props.auth} googleLogin={props.googleLogin} /> : <ImpLogin auth={props.auth} />}
      </div>
    </div>
  )
}

const SignInGoogleBase = (props) => {
  // eslint-disable-next-line
  const clicked = async (event) => {
    try {
      await props.googleLogin()

      // props.permissions.setFinishedInitialLoad(false)
      // props.permissions.setManualLoginLoad(false)
    } catch (e) {
      handleGoogleError(e, props)
    }
    event.preventDefault()
  }

  return (
    <div className='flex'>
      <button className='bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-full inline-flex items-center ml-auto mr-auto' onClick={async (e) => await clicked(e)}>
        <img className='fill-current w-6 h-6 mr-2' src={GLogo} alt='GoogleLogo' />
        Sign In With Google
      </button>
    </div>
  )
}

const SignInImpBase = (props) => {
  const [token, setToken] = useState('')

  const inputTokenChange = (val) => {
    setToken(val)
  }

  const clickedToken = async (e) => {
    await signInWithCustomToken(props.auth, token)
  }

  return (
    <div className='flex'>
      <input className='appearance-none border bg-gray-200 active:bg-white focus:outline-none focus:shadow-outline-indigo focus:bg-white rounded-md text-gray-600 leading-tight' placeholder='Token' defaultValue={''} onChange={(e) => inputTokenChange(e.target.value)} type='text' />
      <button className='bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-full inline-flex items-center ml-auto mr-auto focus:outline-none' onClick={async () => await clickedToken()}>
        Sign In With Token
      </button>
    </div>
  )
}

const SignInMicrosoftBase = (props) => {
  const clicked = async (event) => {
    try {
      await props.microsoftLogin()
    } catch (e) {
      console.log(e)
    }
    event.preventDefault()
  }

  return (
    <div className='flex my-2'>
      <button className='bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-full inline-flex items-center ml-auto mr-auto' onClick={async (e) => await clicked(e)}>
        <img className='fill-current w-6 h-6 mr-2' src={MLogo} alt='MicrosoftLogo' />
        Sign In With Microsoft
      </button>
    </div>
  )
}

const handleGoogleError = (e, props) => {
  const HTTPERROR = 'HTTP Cloud Function returned an error:'
  const AUTHERROR = 'auth/internal-error'

  if (e.code === AUTHERROR) {
    const innerErr = JSON.parse(e.message.replace(HTTPERROR, ''))
    alertify.error(innerErr.error.message)
  } else {
    alertify.error(e.message)
  }
}

const ImpLogin = SignInImpBase
const SignInGoogle = SignInGoogleBase
const SignInMicrosoft = SignInMicrosoftBase

export default Login
