import React from 'react'

const UnauthorizedPage = (props) => {
  return (
    <div className='px-2 py-2 h-full'>
      <div className='px-2 py-4 bg-gray-200 text-center rounded-md text-gray-600 h-full flex flex-col justify-center'>
        <h1>You do not have the minimum permissions needed to view this page</h1>
      </div>
    </div>
  )
}

export default UnauthorizedPage
