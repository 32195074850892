import React, { useState, useContext } from 'react'
import alertify from 'alertifyjs'
import NewUserList from './NewUserList'
import EditGroupsList from './EditGroupsList'
import { AuthContext } from 'services/context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const ImportNewUsers = (props) => {
  const [emails, setEmails] = useState([])
  const [groups, setGroups] = useState([])
  const [importing, setImporting] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  const handleEmailsChange = (changedEmails) => {
    setEmails(changedEmails)
  }

  const handleGroupsChange = (changedGroups) => {
    setGroups(changedGroups)
  }

  const importUsers = () => {
    // Preliminary error checks
    const onlyEmails = emails.map((e) => e?.email)

    if (emails.filter((u) => u?.email === '').length > 0) {
      alertify.error('There are blank emails')
      return
    }
    if (groups.filter((g) => g === '').length > 0) {
      alertify.error('There are blank group selections')
      return
    }
    if (hasDuplicates(onlyEmails)) {
      alertify.error('There are duplicate emails')
      return
    }
    if (hasDuplicates(groups)) {
      alertify.error('There are duplicate groups')
      return
    }

    setImporting(true)

    authService.importUsers(emails, groups).then((data) => {
      setImporting(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Successfully imported users')
      }
    })
  }

  const hasDuplicates = (array) => {
    return new Set(array).size !== array.length
  }

  return (
    <div className='relative border-2 border-transparent'>
      <div className='mb-16'>
        <div className='mt-6 w-full'>
          <NewUserList updateParentEmails={handleEmailsChange} />
        </div>
        <div className='mt-6 w-full'>
          <EditGroupsList updateParentGroups={handleGroupsChange} allGroups={props.allGroups} />
        </div>
      </div>
      <button
        disabled={emails.length === 0}
        onClick={importUsers}
        className={`absolute right-2 bottom-2 text-white font-medium text-md leading-5 py-2 px-4 h-10 rounded-md focus:outline-none ${emails.length === 0 ? 'bg-gray-400 pointer-events-none' : 'bg-blue-600 hover:bg-blue-500 cursor-pointer'} `}>
        <div className='float-left'>Import Users</div>
        {importing ? <FontAwesomeIcon className={`ml-1 animate-spin fill-current text-blue-400 opacity-60`} size='lg' icon={faCircleNotch} /> : ''}
      </button>
    </div>
  )
}

export default ImportNewUsers
