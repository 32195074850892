import React, { useEffect, useState, useRef, useContext } from 'react'
import { faMinusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import alertify from 'alertifyjs'
import Select from 'react-select'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, doc, getDoc, getDocs, query, collection } from 'firebase/firestore'

const GroupsCard = (props) => {
  const [userGroups, setUserGroups] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [group, setGroup] = useState()
  const [adding, setAdding] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi
  const db = getFirestore(uContext.firebaseApp)

  const groupSelectRef = useRef()

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
  }

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, `users/${props.uid}`)
      const snapShot = await getDoc(docRef)
      if (snapShot.exists()) {
        const user = snapShot.data()
        setUserGroups(user.inGroups ? user.inGroups : [])
      }
    }
    if (props.uid) fetchData()

    async function fetchGroups() {
      const q = query(collection(db, 'groups'))
      const snapShot = await getDocs(q)
      const g = []
      snapShot.forEach((d) => {
        g.push({ label: d.data().name, value: d.data().name })
      })
      setAllGroups(g)
    }

    if (allGroups.length === 0) {
      fetchGroups()
    }
    // eslint-disable-next-line
  }, [props.uid])

  const refreshGroups = () => {
    authService.getUser(props.uid).then((user) => {
      groupSelectRef.current.setValue('')
      setGroup()
      setUserGroups(user.inGroups)
      props.setGroups(userGroups)
    })
  }

  const addToGroup = () => {
    if (!adding) {
      setAdding(true)
      authService.addUserToGroup(props.uid, group.toLowerCase()).then((d) => {
        if (!d.success) {
          setAdding(false)
          alertify.error(d.error)
        } else {
          setAdding(false)
          alertify.success('Added User to Group')
          refreshGroups()
          props.setGroups(userGroups)
        }
      })
    }
  }

  const removeFromGroup = (group) => {
    alertify.confirm(
      'Remove User',
      `Remove user from ${group.name.toUpperCase()}?`,
      () => {
        authService.removeUserFromGroup(props.uid, group.name.toLowerCase()).then((d) => {
          if (!d.success) {
            alertify.error(d.error)
          } else {
            alertify.success('Removed from Group')
            setUserGroups(userGroups.filter((x) => x.name.toLowerCase() !== group.name.toLowerCase()))
            props.setGroups(userGroups)
          }
        })
      },
      () => {},
    )
  }

  const filterGroups = (allGroups, userGroups) => {
    return allGroups.filter((group) => {
      return !userGroups.some((g) => g.name === group.value)
    })
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-4 mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>{props.header}</h3>
          </div>
        </div>
      </div>
      <div className='bg-white px-2 shadow-xl rounded-b-lg'>
        <div className={`opacity-100 w-full flex items-center justify-between p-6 space-x-6`}>
          <div className='flex-1'>
            <div className='flex flex-col space-y-2 font-medium text-md '>
              {!userGroups ? (
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
              ) : (
                userGroups?.map((group, index) => {
                  return (
                    <div key={group.name} className='flex flex-row pr-4'>
                      <h3 className='w-full border-b border-gray-300 text-gray-500 text-sm leading-5 font-medium truncate'>{group.name.toUpperCase()}</h3>
                      <button className='focus:outline-none' onClick={() => removeFromGroup(group)}>
                        <FontAwesomeIcon icon={faMinusCircle} color='gray' />
                      </button>
                    </div>
                  )
                })
              )}
              <div>
                <Select ref={groupSelectRef} placeholder='Group' options={filterGroups(allGroups, userGroups)} onChange={(option) => setGroup(option.value)} menuPlacement='auto' styles={customStyles} />
              </div>
            </div>
          </div>
        </div>
        <div className='border-t border-gray-200'>
          <div className='-mt-px flex'>
            <button disabled={adding} className='w-0 flex-1 flex border-r border-gray-200 cursor-pointer' onClick={addToGroup}>
              <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
                <span className='ml-3'>{allGroups.length === 0 && !userGroups ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-xl' /> : 'Add Group'}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default GroupsCard
