import React, { useState } from 'react'

const GroupBox = (props) => {
  const [groupName, setGroupName] = useState('')
  const [groupDesc, setGroupDesc] = useState('')

  return (
    <div className='p-2 flex'>
      <div className='flex flex-col w-2/3'>
        <input
          className={`appearance-none border rounded-tl-md w-full py-2 px-3 leading-tight ${!props.isAuthAdmin ? 'placeholder-white bg-gray-300 cursor-not-allowed opacity-75' : 'text-gray-700 bg-gray-100 focus:bg-white focus:outline-none shadow'}`}
          type='text'
          placeholder={props.groupName}
          value={groupName}
          disabled={!props.isAuthAdmin}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <textarea
          className={`appearance-none border rounded-bl-md w-full py-2 px-3 leading-tight ${!props.isAuthAdmin ? 'placeholder-white bg-gray-300 cursor-not-allowed opacity-75' : 'text-gray-700 bg-gray-100 focus:bg-white focus:outline-none shadow'}`}
          type='text'
          placeholder={props.groupDesc}
          value={groupDesc}
          disabled={!props.isAuthAdmin}
          onChange={(e) => setGroupDesc(e.target.value)}
        />
      </div>

      <button
        onClick={() => props.createGroup(groupName, groupDesc)}
        className={`w-1/3 text-white font-bold py-2 px-4 rounded-r-md focus:outline-none float-center 
        ${!props.isAuthAdmin ? 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}
        disabled={!props.isAuthAdmin}>
        {props.buttonText}
      </button>
    </div>
  )
}

export default GroupBox
