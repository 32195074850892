import React, { useState, useRef, useContext } from 'react'
import alertify from 'alertifyjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faUser, faUsers, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import { AuthContext } from 'services/context/AuthContext'

const User = (props) => {
  const [front, setFront] = useState(true)
  const [selectedGroup, setGroup] = useState('')
  const [admin, setAdmin] = useState(props.user.admin)
  const [groups, setUserGroups] = useState(props.user.inGroups)
  const [loadingGroup, setLoadingGroup] = useState(false)
  const [isDisabled, setIsDisabled] = useState(props.user.disabled)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  const groupSelectRef = useRef()
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
  }
  const groupOptions = props.allGroups.map((g) => ({ label: g.name, value: g.name }))

  const disableUser = () => {
    alertify.confirm(
      isDisabled ? 'Enable User' : 'Disable User',
      'Do you want to ' + (isDisabled ? 'enable user: ' : 'disable user: ') + props.user.email,
      () => {
        authService.disableUser(props.user.uid, !isDisabled).then((data) => {
          if (!data.success) {
            alertify.error(data.error)
          } else {
            alertify.success((isDisabled ? 'Enabled User: ' : 'Disabled User: ') + props.user.email)
            setIsDisabled((isDisabled) => !isDisabled)
          }
        })
      },
      () => {},
    )
  }

  const addToGroup = () => {
    setLoadingGroup(true)
    authService.addUserToGroup(props.user.uid, selectedGroup.toLowerCase()).then((d) => {
      setLoadingGroup(false)
      if (!d.success) {
        alertify.error(d.error)
      } else {
        alertify.success('Added User to Group')
        setUserGroups(d.data.inGroups)
      }
    })
  }

  const removeFromGroup = (group) => {
    alertify.confirm(
      'Remove Group',
      `Remove ${props.user.email} from ${group.name.toUpperCase()}?`,
      () => {
        setLoadingGroup(true)
        authService.removeUserFromGroup(props.user.uid, group.name.toLowerCase()).then((d) => {
          setLoadingGroup(false)
          if (!d.success) {
            alertify.error(d.error)
          } else {
            alertify.success('Removed from Group')
            setUserGroups(groups.filter((x) => x.name.toLowerCase() !== group.name.toLowerCase()))
          }
        })
      },
      () => {},
    )
  }
  const setAdminUser = () => {
    alertify.confirm(
      'Change Status',
      `change admin status of ${props.user.email}?`,
      () => {
        authService.makeAuthAdmin(props.user.uid).then((d) => {
          if (!d.success) {
            alertify.error(d.error)
          } else {
            alertify.success('Admin Access granted')
            setAdmin(d.data.admin)
          }
        })
      },
      () => {},
    )
  }

  const handleGroup = (groupOption) => {
    setGroup(groupOption.value)
  }

  return (
    <li className={(isDisabled ? 'opacity-50 ' : '') + 'relative col-span-1 bg-white rounded-lg shadow hover:shadow-xl'}>
      <span
        role='checkbox'
        onClick={() => disableUser()}
        tabIndex='0'
        aria-checked='false'
        className={`${isDisabled ? 'bg-red-400' : 'bg-blue-400'} absolute top-1 right-1  h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
        <span aria-hidden='true' className={`${isDisabled ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
      </span>
      <div className='w-full h-36 overflow-y-auto'>
        <div className={`${!front ? 'hidden' : 'transition-transform opacity-100'} w-full flex items-center justify-between p-6 space-x-6`}>
          <img className='w-12 h-12 bg-gray-300 rounded-full flex-shrink-0' src={props.user.photoURL} alt='' />
          <div className='flex-1 truncate'>
            <NavLink to={'/user/' + props.user.uid} className='flex items-center space-x-3'>
              <h3 className='text-gray-900 text-md leading-5 font-medium truncate'>{props.user.displayName ?? 'No Name'}</h3>
            </NavLink>
            <p className='mt-1 text-gray-500 text-sm leading-5 truncate'>{props.user.email}</p>
          </div>
        </div>
        <div className={`${front ? 'hidden' : 'transition-transform opacity-100'} transition-transform w-full flex items-center justify-between p-6 space-x-6`}>
          <div className='flex-1 '>
            <div className='flex flex-col space-y-2 font-medium text-md '>
              {loadingGroup ? (
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
              ) : (
                groups?.map((group, index) => {
                  return (
                    <div key={group.name} className='flex flex-row pr-4'>
                      <h3 className='w-full border-b border-gray-300 text-gray-500 text-sm leading-5 font-medium truncate'>{group.name.toUpperCase()}</h3>
                      <button className='focus:outline-none' onClick={() => removeFromGroup(group)}>
                        <FontAwesomeIcon icon={faMinusCircle} color='gray' />
                      </button>
                    </div>
                  )
                })
              )}
              <div className='border-b border-gray-400'>
                <Select ref={groupSelectRef} placeholder='Group' menuPosition='fixed' onChange={handleGroup} options={groupOptions} menuPlacement='auto' styles={customStyles} />
              </div>
            </div>
          </div>
        </div>
        {props.isAdmin ? (
          <div className={`${!front ? 'transition-transform hidden' : 'transition-transform opacity-100'} w-full flex items-center justify-end px-6 pb-2`}>
            <span className='text-gray-900 text-sm pr-2'>Admin</span>
            <span
              role='checkbox'
              onClick={() => setAdminUser()}
              tabIndex='0'
              aria-checked='false'
              className={`${admin ? 'bg-blue-400' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
              <span aria-hidden='true' className={`${admin ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='border-t border-gray-200'>
        <div className='-mt-px flex'>
          <div onClick={() => setFront(!front)} className='cursor-pointer w-0 flex-1 flex border-r border-gray-200'>
            <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
              <FontAwesomeIcon icon={front ? faUsers : faUser} color='gray' />
              <span className='ml-3'>{front ? 'Groups' : 'Profile'}</span>
            </div>
          </div>
          <div onClick={() => addToGroup()} className={`${(front || !selectedGroup) && 'hidden'} -ml-px w-0 flex-1 flex cursor-pointer`}>
            <div className='relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
              <span className='ml-3'>{loadingGroup ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-xl' /> : 'Add Group'}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default User
