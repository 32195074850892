import React, { useEffect, useState, useContext } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from 'services/context/AuthContext'

const RolesCard = (props) => {
  const [roles, setRoles] = useState(null)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  useEffect(() => {
    async function fetchData() {
      const data = await authService.getUser(props.uid)
      const userRoles = []
      if (data?.inGroups)
        data.inGroups.forEach((g) => {
          if (g.hasRoles)
            g.hasRoles.forEach((r) => {
              if (userRoles.filter((role) => role.name.toUpperCase() === r.name.toUpperCase()).length === 0) {
                userRoles.push(r)
              }
            })
        })
      setRoles(userRoles)
    }

    if (props.uid && props.groups) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [props.uid, props.groups])

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-4 mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>{props.header}</h3>
          </div>
        </div>
      </div>
      <div className='bg-white px-2 shadow-xl rounded-b-lg'>
        <div className={`opacity-100 w-full flex items-center justify-between p-6 space-x-6`}>
          <div className='flex-1 truncate '>
            <div className='flex flex-col space-y-2 font-medium text-md '>
              {!roles ? (
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
              ) : (
                roles?.map((role, index) => {
                  return (
                    <div key={role.name} className='flex flex-row pr-4'>
                      <h3 className={(index !== roles.length - 1 ? 'border-b ' : '') + 'w-full border-gray-300 text-gray-500 text-sm leading-5 font-medium truncate'}>{role.name.toUpperCase()}</h3>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolesCard
