import React from 'react'

const GroupsList = (props) => {
  return (
    <div>
      <div className='font-medium leading-5 text-gray-500 text-md px-2'>Adding These Groups to the new Users</div>
      {props.groups.length === 0 ? (
        <div className='border-b w-full py-2 px-3 text-gray-700 leading-tight'>None</div>
      ) : (
        props.groups.map((group) => (
          <div key={group} className='mb-2 flex justify-center'>
            <div className='border-b w-full py-2 px-3 text-gray-700 leading-tight'>{group.toUpperCase()}</div>
          </div>
        ))
      )}
    </div>
  )
}

export default GroupsList
