import React, { useState, useContext } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { FiLogOut } from 'react-icons/fi'
import { CookieAuthApi } from '../../../../services/api'
import { AuthContext } from '../../../../services/context/AuthContext'

const UserProfileMenu = (props) => {
  const [isOpen, setOpen] = useState(false) // eslint-disable-next-line
  //const [cookies, setCookie] = useCookies(['HGL'])
  const cookieAuthService = new CookieAuthApi()
  const uContext = useContext(AuthContext)

  const clickLogout = async () => {
    await cookieAuthService.logout(uContext.user)
    //setCookie('HGL', '0', {maxAge: 0, domain: domain})
    await uContext.logOut()
  }

  return (
    <div className='ml-3 relative'>
      <div>
        <button className='max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline' onClick={() => setOpen(!isOpen)}>
          <img className='h-8 w-8 rounded-full' src={uContext.user.photoURL} alt='' />
        </button>
      </div>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg'>
            <div className='py-1 rounded-md bg-white shadow-xs'>
              <span href='#' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150'>
                Logged in as:
                <br />
                {uContext.user.displayName}
              </span>

              {/* <a href="#" className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                <FiSettings className="mr-4" />
                Settings
              </a>               */}

              <div className='border-t border-gray-100'></div>

              <div className='cursor-pointer'>
                <div className='group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900' onClick={async () => await clickLogout()}>
                  <FiLogOut className='mr-4' />
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}

export default UserProfileMenu
