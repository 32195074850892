import React from 'react'
import UserProfileMenu from './UserProfileMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Online from '../../../../pages/components/Online'

const Header = (props) => {
  return (
    <div className='relative z-10 flex-shrink-0 flex h-16 bg-white shadow-xs'>
      <button onClick={props.showSidebar} className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600'>
        <svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
        </svg>
      </button>
      <div className='flex-1 px-4 flex justify-between'>
        <div className='flex-1 flex'>
          <div className='w-full flex flex-row justify-between md:ml-0'>
            <div className='mt-auto mb-auto font-mono text-emerald-600 border-2 pr-2 border-emerald-600 rounded-full select-none items-center flex flex-row'>
              <FontAwesomeIcon className='m-1 text-2xl' icon={faExclamationCircle} />
              <p className='text-lg md:text-xl mt-0'>{props.pageTitle}</p>
              <p className='float-right text-xs z-50 text-gray-500 mt-2'>BETA</p>
            </div>
          </div>
          <div className='flex flex-col justify-center invisible sm:visible'>
            <Online />
          </div>
        </div>

        <div className='ml-4 flex items-center md:ml-6 relative'>
          <UserProfileMenu />
        </div>
      </div>
    </div>
  )
}

export default Header
