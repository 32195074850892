import React, { useEffect, useState, useContext } from 'react'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import alertify from 'alertifyjs'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlus, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, collection, query, orderBy, getDocs } from 'firebase/firestore'

const ExpandableRow = (props) => {
  const [group, setGroup] = useState(null)
  const [groupDesc, setGroupDesc] = useState(props.group.description)
  const [collapsed, setCollapsed] = useState(true)
  const [inputRole, setInputRole] = useState('')
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi
  const db = getFirestore(uContext.firebaseApp)

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
    container: (provided) => ({
      ...provided,
      zIndex: 51,
    }),
  }

  const allRoleOptions = props.allRoles.map((r) => ({ label: r.name, value: r.name }))
  const fetchData = async () => {
    const groupsRoles = await getDocs(query(collection(db, `groups/${props.group.name}/roles`), orderBy('name')))
    //const groupsRoles = await props.firebase.firestore().collection('groups').doc(props.group.name).collection('roles').orderBy('name').get()
    let r = groupsRoles.docs.map((doc) => doc.data())
    setRoles(r)
  }

  useEffect(() => {
    async function dataPull() {
      if (props.group) {
        setGroup(props.group)
        const groupsRoles = await getDocs(query(collection(db, `groups/${props.group.name}/roles`), orderBy('name')))
        let r = groupsRoles.docs.map((doc) => doc.data())
        setRoles(r)
      }
    }
    dataPull()
  }, [inputRole, props.firebase, props.group, db])

  const addRole = () => {
    if (roles.filter((r) => r.name.toLowerCase() === inputRole.toLowerCase()).length > 0) {
      alertify.error('This Group already has that Role')
      return
    }
    setLoading(true)
    authService.addRoleToGroup(inputRole.toLowerCase(), group.name).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Added Role to Group')
        fetchData()
      }
    })
  }

  const deleteRole = (val) => {
    if (roles.filter((r) => r.name.toLowerCase() === val.toLowerCase()).length === 0) {
      alertify.error('This Group does not have that Role')
      return
    }
    setLoading(true)
    authService.removeRoleFromGroup(val, group.name).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('removed role from group')
        fetchData()
      }
    })
  }

  const updateDesc = () => {
    setLoading(true)
    authService.updateGroupDesc(group.name, groupDesc).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Group description updated')
      }
    })
  }

  return !group ? (
    <tr>
      <td>
        <LoadIndicator className='justify-center' height={40} width={40} />
      </td>
    </tr>
  ) : (
    [
      <tr key={group.name} className={props.bgColor}>
        <td className='px-2 py-2 flex justify-start'>
          <button
            className='focus:outline-none'
            onClick={() => {
              setCollapsed(!collapsed)
            }}>
            {collapsed ? <FontAwesomeIcon icon={faPlus} className='text-blue-500' /> : <FontAwesomeIcon icon={faMinus} className='text-gray-500 text-md' />}
          </button>
        </td>

        <td className='px-2 py-2'>
          <NavLink to={'/group/' + group.name} className='truncate'>
            <div className='font-bold text-md leading-5 truncate'>
              {group.name.toUpperCase()}
              {groupDesc ? <span className='font-normal'>: {groupDesc}</span> : ''}
            </div>
          </NavLink>
        </td>

        <td className='px-2 py-2 w-full flex justify-end'>
          <button className={`focus:outline-none ${!props.isAuthAdmin ? 'cursor-not-allowed' : ''}`} disabled={!props.isAuthAdmin} onClick={() => props.deleteGroup(group.name)}>
            <FontAwesomeIcon icon={faMinusCircle} className='text-red-500 text-xl' />
          </button>
        </td>
      </tr>,
      <tr key={group.name + 'Group Description'} hidden={collapsed} className={props.bgColor + ' border-t'}>
        <td />
        <td className='px-1 py-2 text-gray-600'>
          <textarea
            className={`w-full rounded-md py-1 px-2 resize-none ${!props.isAuthAdmin ? 'bg-gray-300 cursor-not-allowed opacity-75' : 'bg-gray-100 border border-gray-300'}`}
            placeholder='Group Description'
            type='text'
            disabled={!props.isAuthAdmin}
            value={groupDesc || ''}
            onChange={(e) => setGroupDesc(e.target.value)}></textarea>
        </td>
        <td className='w-full flex justify-center pt-2'>
          <button
            onClick={updateDesc}
            disabled={groupDesc?.length <= 0 || !groupDesc || loading || !props.isAuthAdmin}
            className={`text-white font-bold py-2 px-4 rounded-md focus:outline-none justify-center ${groupDesc?.length <= 0 || !groupDesc || !props.isAuthAdmin ? 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' /> : 'Update'}
          </button>
        </td>
      </tr>,
      <tr key={group.name + 'Expanded'} hidden={collapsed} className={props.bgColor + ' border-t'}>
        <td />
        <td className='px-1 py-2 text-gray-600'>
          {loading ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600 text-sm' />
            </div>
          ) : (
            roles.map((role, index) => {
              return (
                <div key={index}>
                  <button className={`focus:outline-none mr-2 ${!props.isAuthAdmin ? 'cursor-not-allowed' : ''}`} disabled={!props.isAuthAdmin} onClick={() => deleteRole(role.name)}>
                    <FontAwesomeIcon icon={faMinusCircle} className='text-gray-400' />
                  </button>
                  <span>{role.name}</span>
                </div>
              )
            })
          )}
        </td>
        <td className='px-2 py-2 w-full flex flex-col justify-end border border-gray-300 rounded-md space-y-2 my-2 mr-2'>
          <div className='w-full bg-gray-100 border border-gray-300 rounded-md'>
            <Select placeholder='Roles' menuPosition='fixed' onChange={(o) => setInputRole(o.value)} options={allRoleOptions} menuPlacement='auto' styles={customStyles} />
          </div>
          <button onClick={addRole} disabled={loading || !props.isAuthAdmin} className={`text-white font-bold py-2 px-4 rounded-md focus:outline-none ${!props.isAuthAdmin ? 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' /> : 'Add'}
          </button>
        </td>
      </tr>,
    ]
  )
}

export default ExpandableRow
