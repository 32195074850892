import React, { useContext, useEffect, useState } from 'react'
import RolesCard from './components/RolesCard'
import UsersCard from './components/UsersCard'
import DescCard from './components/DescCard'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, doc, getDoc } from 'firebase/firestore'

const Group = (props) => {
  const [group, setGroup] = useState(null)
  const uContext = useContext(AuthContext)
  const db = getFirestore(uContext.firebaseApp)

  useEffect(() => {
    const groupName = props.match.params.group
    if (groupName) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [props.match.params.group])

  const fetchData = async () => {
    const gObj = await getDoc(doc(db, 'groups', props.match.params.group))
    if (gObj) {
      const group = gObj.data()
      setGroup(group)
    }
  }

  return (
    <div className='w-full flex flex-row justify-center items-center'>
      <div className='w-full flex flex-col items-center justify-end space-y-2 space-x-2'>
        <div className='w-full px-2 py-4 flex flex-col justify-center items-center'>
          <div className='w-full max-w-6xl px-2'>
            <h2 className='flex justify-center items-center bg-gray-100 text-gray-600 text-lg font-bold px-2 py-3 rounded-lg shadow-2xl border border-gray-200'>{group?.name.toUpperCase()}</h2>
          </div>
          <div className='w-full max-w-6xl px-2'>
            <DescCard groupName={group?.name} groupDesc={group?.description}></DescCard>
          </div>
          <div className='w-full max-w-6xl px-2 pt-2'>
            <RolesCard group={group?.name} />
          </div>
          <div className='w-full max-w-6xl px-2'>
            <UsersCard group={group?.name} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Group
