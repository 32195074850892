import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../../assets/img/pb.png'
import { Footer } from './components'
import Header from './components/Header'
import Router from '../../../router/AuthenticatedRoutes'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers, faUser, faUserTag } from '@fortawesome/free-solid-svg-icons'
import fire from '../../../assets/img/fire2.svg'
import custBackground from '../../../assets/img/iam_custBackground.jpg'
import { AuthContext } from '../../../services/context/AuthContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Shell = (props) => {
  const [isToggleOn, setIsToggleOn] = useState(false)
  const [routes, setRoutes] = useState([])
  const [isIt, setIsIt] = useState(false)
  const user = useContext(AuthContext)

  useEffect(() => {
    let currentIsIt = false
    if (user.hasAnyGroups(['auth-admin', 'auth-editor'])) {
      currentIsIt = true
    }
    if (isIt !== currentIsIt) {
      const routes = [
        { name: 'Home', path: '/Home', icon: faHome, hasAccess: currentIsIt },
        { name: 'Users', path: '/Users', icon: faUser, hasAccess: currentIsIt },
        {
          name: 'Groups',
          path: '/Groups',
          icon: faUsers,
          hasAccess: currentIsIt,
        },
        {
          name: 'Roles',
          path: '/Roles',
          icon: faUserTag,
          hasAccess: currentIsIt,
        },
      ]

      setIsIt(currentIsIt)
      setRoutes(routes)
    } // eslint-disable-next-line
  }, [user])

  const hideSidebar = () => {
    setIsToggleOn(false)
  }

  const showSidebar = () => {
    setIsToggleOn(true)
  }

  return user.inGroups ? (
    <div className='h-screen flex overflow-hidden bg-gray-100'>
      {/* Mobile sidebar */}
      <div>
        <div onClick={hideSidebar} className={classNames('fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300', isToggleOn ? 'opacity-75 pointer-events-auto' : 'opacity-0 pointer-events-none')}></div>

        <div className={classNames('fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-gray-800 transform ease-in-out duration-300', isToggleOn ? 'translate-x-0' : '-translate-x-full')}>
          <div className='absolute top-0 right-0 -mr-14 p-1'>
            {/* Close menu button */}
            {isToggleOn && (
              <button onClick={hideSidebar} className='flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600'>
                <svg className='h-6 w-6 text-white' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                </svg>
              </button>
            )}
          </div>

          <div className='flex-shrink-0 flex items-center h-16 px-4 bg-gray-900'>
            <img className='h-12 w-auto' src={logo} alt='FSLogo' />
          </div>

          <div className='flex-1 h-0 overflow-y-auto'>
            <nav className='px-2 py-4'>
              {routes.map((route) => (
                <NavLink key={route.name} exact activeClassName='bg-gray-700' to={route.path} onClick={hideSidebar} className='mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150'>
                  <FontAwesomeIcon className='mr-3' size='2x' icon={route.icon} color='white' />
                  {route.name}
                </NavLink>
              ))}
            </nav>
            <div className='px-2 text-gray-600 text-sm'>Version: {process.env.REACT_APP_VERSION}</div>
          </div>
        </div>
      </div>

      {/* <Sidebar /> */}

      <div className='relative z-10 flex flex-col w-0 flex-1 overflow-hidden'>
        {user.impersonator ? <div className='relative z-10 h-6 bg-blue-500 text-center'>Impersonating User</div> : null}
        <div
          className='absolute z-0 inset-0 bg-cover bg-gray-100 opacity-75'
          style={{
            backgroundImage: 'Url(' + (process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'STAGING' || process.env.REACT_APP_ENV === 'PRODUCTION' ? fire : custBackground) + ')',
          }}
        />

        <Header showSidebar={showSidebar} pageTitle='auth' />

        <main className='flex-1 relative overflow-y-auto pt-0 focus:outline-none' tabIndex='0'>
          <Router />
        </main>

        <Footer />
      </div>
    </div>
  ) : (
    <div className='flex w-full h-screen bg-gray-400' />
  )
}

export default withRouter(Shell)
