import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../src/assets/img/google_icon.png'
import { getFirestore, collection, where, onSnapshot, query } from 'firebase/firestore'
import { AuthContext } from '../../services/context/AuthContext'

const Online = (props) => {
  const [users, setUsers] = useState([])
  const uContext = useContext(AuthContext)
  // eslint-disable-next-line
  const baseUrl = process.env.REACT_APP_IAM_BASE_URL
  const db = getFirestore(uContext.firebaseApp)

  useEffect(() => {
    const q = query(collection(db, 'auth_details/active_users/users'), where('state', '==', 'online'))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let u = []
      snapshot.docs.forEach((doc) => {
        u.push(doc.data())
      })
      setUsers(u)
    })

    return () => unsubscribe()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div>
        <div className='flex'>
          {users.length === 0 ? (
            <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
          ) : (
            users.map((x, index) => {
              return (
                <div key={`${x.email}-${index}`} className='relative group -ml-2'>
                  <a href={'/email?email=' + x.email} target='_blank' rel='noopener noreferrer'>
                    <div className='absolute mt-12 px-2 -ml-15 rounded-md py-1 text-white bg-green-500 invisible group-hover:visible text-xs'>{x.email}</div>
                    <img key={`${x.email}-${index}`} className={`${x.state === 'offline' && 'opacity-25'} inline-block h-9 w-9 rounded-full border-2 border-green-500 text-white shadow-solid bg-gray-300`} src={x?.photoURL !== '' ? x.photoURL : logo} alt='user' />
                  </a>
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

export default Online
