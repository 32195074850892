import React, { useState } from 'react'
import ImportNewUsers from './ImportNewUsers'
import CopyNewUsers from './CopyNewUsers'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreateUserAside = (props) => {
  const [selectedTab, setSelectedTab] = useState('import')

  return (
    <div>
      <div
        onClick={() => {
          props.setShowBar(!props.doShowBar)
        }}
        className={classNames('fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300', props.doShowBar ? 'opacity-75 pointer-events-auto' : 'opacity-0 pointer-events-none')}></div>

      <div className={classNames('fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-gray-100 transform ease-in-out duration-300', props.doShowBar ? 'translate-x-0' : '-translate-x-full')}>
        <div className='absolute top-16 right-0 -mr-14 p-1'>
          {/* Close button */}
          {props.doShowBar && (
            <button
              onClick={() => {
                props.setShowBar(!props.doShowBar)
              }}
              className='flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600'>
              <svg className='h-6 w-6 text-white' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
              </svg>
            </button>
          )}
        </div>
        <div className='flex-1 pt-16 overflow-y-auto'>
          <nav className='px-2 py-4'>
            <ul className='flex'>
              <li
                onClick={() => {
                  setSelectedTab('import')
                }}
                className={`${
                  selectedTab === 'import' ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-500'
                } -mt-px border-b-2  pb-4 px-4 inline-flex items-center text-md leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 w-1/2 text-center cursor-pointer`}>
                <div className='w-full text-center'>Import</div>
              </li>
              <li
                onClick={() => {
                  setSelectedTab('copy')
                }}
                className={`${
                  selectedTab === 'copy' ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-500'
                } -mt-px border-b-2  pb-4 px-4 inline-flex items-center text-md leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 w-1/2 mr-6 text-center cursor-pointer `}>
                <div className='w-full text-center'>Copy</div>
              </li>
            </ul>
          </nav>
          {selectedTab === 'import' ? (
            <div className='w-full'>
              <ImportNewUsers allGroups={props.allGroups} />
            </div>
          ) : (
            <div className='w-full'>
              <CopyNewUsers />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateUserAside
