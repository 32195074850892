import axios from 'axios'

export default class CookieAuthApi {
  constructor() {
    this.baseURL = `/auth`
  }

  async login(user) {
    if (!user) {
      return { success: false, reason: 'No User Signed In' }
    }
    const token = await user.getIdToken(true)

    let query = {
      method: 'login',
      idToken: token,
    }

    try {
      const response = await axios.post(this.baseURL, query, {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      })
      return response.data
    } catch (error) {
      console.error('Failed cookie login', error.message)
      return error.message
    }
  }

  async status() {
    let query = {
      method: 'status',
    }

    try {
      const response = await axios.post(this.baseURL, query)
      return response.data
    } catch (error) {
      //this error just means that the user is not logged in
      return null
    }
  }

  async logout(user) {
    if (!user) {
      return { success: false, reason: 'No User Signed In' }
    }
    const token = await user.getIdToken(true)

    let query = {
      method: 'logout',
    }

    try {
      const response = await axios.post(this.baseURL, query, {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      })

      return response.data
    } catch (error) {
      console.error('Failed cookie logout', error.message)
      return error.message
    }
  }
}
