import React from 'react'

const ErrorPage = (props) => {
  return (
    <div className='px-2 py-2 content-center h-full'>
      <div className='px-2 py-4 bg-red-100 rounded-md text-red-600 font-bold text-center h-full flex flex-col justify-center'>
        <h1>ERROR: Conact a System Administrator</h1>
      </div>
    </div>
  )
}

export default ErrorPage
