import React, { useEffect, useState, useContext } from 'react'
import { SearchApi } from 'services/api/'
import alertify from 'alertifyjs'
import ExpandableRow from './components/ExpandableRow'
import PaginatedRows from '../components/PaginatedRows'
import Modal from './components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SearchBar from 'pages/components/SearchBar'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, collection, getDocs } from 'firebase/firestore'

const Groups = (props) => {
  const [showModal, setModal] = useState(false)
  const [groups, setGroups] = useState(null)
  const [pageSize, setPageSize] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)
  const [allRoles, setAllRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [total, setTotal] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchTimeout, setSearchTimeout] = useState(0)
  const [isAuthAdmin, setIsAuthAdmin] = useState(false)
  const [groupChange, setGroupChange] = useState(false)

  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi
  const db = getFirestore(uContext.firebaseApp)

  const searchService = new SearchApi(uContext.user)

  useEffect(() => {
    setIsAuthAdmin(uContext.hasGroup('auth-admin'))
  }, [uContext])

  useEffect(() => {
    const fetchData = async () => {
      setSearchLoading(true)
      const response = await searchService.pagedGroupSearch(searchTerm, pageSize, pageNumber)
      setGroups(response.groups)
      setTotal(response.totalResults)
      setTotalPages(response.totalPages)
      setSearchLoading(false)
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(
      setTimeout(() => {
        fetchData()
        setGroupChange(false)
      }, 800),
    )

    async function fetchRoles() {
      const roles = await getDocs(collection(db, 'roles'))
      setAllRoles(roles.docs.map((doc) => doc.data()))
    }

    if (allRoles.length === 0) {
      fetchRoles()
    }
    // eslint-disable-next-line
  }, [pageSize, pageNumber, searchTerm, groupChange])

  const deleteGroup = async (name) => {
    alertify.confirm(
      'Delete Group',
      'Do you want to delete group: ' + name,
      async () => {
        const result = await authService.deleteGroup(name)

        if (!result.success) {
          alertify.error(result.error)
        } else {
          alertify.success(`Deleted group: ${name}`)
          setGroupChange(true)
          setPageNumber(0)
        }
      },
      () => {
        return
      },
    )
  }

  const closeGroupModal = () => {
    setModal(false)
  }

  const openGroupModal = () => {
    setModal(true)
  }

  const createGroup = async (groupName, groupDesc) => {
    setLoading(true)
    closeGroupModal()

    const result = await authService.createGroup(groupName, groupDesc)
    if (!result.success) {
      alertify.error(result.error)
    } else {
      alertify.success(`Created group: ${groupName} with ${groupDesc}`)
      setGroupChange(true)
      setPageNumber(0)
    }

    setLoading(false)
  }

  const changePageSize = (size) => {
    setPageNumber(0)
    setPageSize(size)
  }

  const nextPage = async () => {
    setPageNumber(pageNumber + 1)
  }

  const prevPage = async () => {
    setPageNumber(pageNumber - 1)
  }

  return (
    <PaginatedRows
      key='groupsPage'
      changePageSize={changePageSize}
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalPages={totalPages}
      nextPage={nextPage}
      prevPage={prevPage}
      rows={groups}
      top={
        <div className='w-full flex'>
          <div className=' px-1 text-gray-600 text-2xl leading-tight text-left flex-none'>Groups</div>
          <div className='flex-1 mr-2 ml-6'>
            <SearchBar searchTerm={searchTerm} handleSearchChange={setSearchTerm} setPageNumber={setPageNumber} searching={searchLoading} totalCount={total} />
          </div>
          <button
            onClick={openGroupModal}
            className={`${isAuthAdmin ? 'bg-blue-600 border border-blue-700 hover:border-blue-500 hover:bg-blue-500' : 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75'} shadow text-white font-bold py-2 px-4 h-10 rounded-md focus:outline-none float-right w-36`}
            disabled={loading || !isAuthAdmin}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100 text-2xl' /> : 'Create Group'}
          </button>
          {showModal ? <Modal isAuthAdmin={isAuthAdmin} closeGroupModal={closeGroupModal} createGroup={createGroup}></Modal> : ''}
        </div>
      }
      elements={
        !groups ? (
          <tr>
            <td className='w-full py-16 text-center'>
              <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600' size='4x' />
            </td>
          </tr>
        ) : (
          groups.map((group, index) => <ExpandableRow key={group.name} group={group} allRoles={allRoles} deleteGroup={deleteGroup} isAuthAdmin={isAuthAdmin} bgColor={index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100'} />)
        )
      }
    />
  )
}

export default Groups
