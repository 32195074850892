import axios from 'axios'

export default class SearchApi {
  constructor(user) {
    this._searchBaseUrl = process.env.REACT_APP_SEARCH_REWRITE_PATH
    this._user = user
  }

  async getConfig() {
    let token = await this._user.getIdToken(true)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }

  async pagedUserSearch(searchTerm, pageSize, pageNumber, onlyActive) {
    const apiUrl = `${this._searchBaseUrl}/user`
    let config = await this.getConfig()
    config.params = { searchTerm, pageSize, pageNumber, onlyActive }

    try {
      const response = await axios.get(apiUrl, config)
      return response.data
    } catch (err) {
      console.error(`Error with user search: ${err.message}`)
      return null
    }
  }

  async pagedUserSearchByGroup(searchTerm, group, pageSize, pageNumber) {
    const apiUrl = `${this._searchBaseUrl}/user/group/${group}`
    let config = await this.getConfig()
    config.params = { searchTerm, pageSize, pageNumber }

    try {
      const response = await axios.get(apiUrl, config)
      return response.data
    } catch (err) {
      console.error(`Error with user search by group: ${err.message}`)
      return null
    }
  }

  async pagedGroupSearch(searchTerm, pageSize, pageNumber) {
    const apiUrl = `${this._searchBaseUrl}/group`
    let config = await this.getConfig()
    config.params = { searchTerm, pageSize, pageNumber }

    try {
      const response = await axios.get(apiUrl, config)
      return response.data
    } catch (err) {
      console.error(`Error with group search: ${err.message}`)
      return null
    }
  }

  stopAllRequests() {
    this._client.stop()
  }
}
