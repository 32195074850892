import React, { useState, useEffect, useContext } from 'react'
import alertify from 'alertifyjs'
import InputBox from 'pages/components/InputBox'
import { AuthContext } from 'services/context/AuthContext'

const AdminCard = (props) => {
  const [user, setUser] = useState('')
  const [isAuthAdmin, setAuthAdmin] = useState(props.isAuthAdmin)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  useEffect(() => {
    setAuthAdmin(props.isAuthAdmin)
  }, [props])

  const makeAuthAdmin = () => {
    authService.makeAuthAdmin(user).then((data) => {
      if (!data.success) {
        alertify.error(data.error)
        return
      }
      alertify.success('Successfuly made ' + data.email + ' an Admin')
    })
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-4 mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>{props.header}</h3>
          </div>
        </div>
      </div>
      <div className='bg-white'>
        <InputBox placeholder='UID' buttonText='Make Admin' isAuthAdmin={isAuthAdmin} onClick={makeAuthAdmin} onChange={setUser} />
      </div>
    </>
  )
}

export default AdminCard
