import React, { useEffect, useState, useContext } from 'react'
import GroupsCard from './components/GroupsCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText, faCoins } from '@fortawesome/free-solid-svg-icons'
import alertify from 'alertifyjs'
import RolesCard from './components/RolesCard'
import UserAccessCard from './components/UserAccessCard'
import PermissionsCard from './components/PermissionsCard'
import GLogo from 'assets/img/google_icon.png'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, getDoc, doc } from 'firebase/firestore'

const User = (props) => {
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [groups, setGroups] = useState([])
  const uContext = useContext(AuthContext)
  const db = getFirestore(uContext.firebaseApp)
  const authService = uContext.AuthApi

  useEffect(() => {
    const uid = props.match.params.uid
    if (uid) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [props.match.params.uid])

  const fetchData = async () => {
    const docRef = doc(db, 'users', props.match.params.uid)
    const docSnapshot = await getDoc(docRef)
    if (docSnapshot.exists()) {
      setUser(docSnapshot.data())
      setIsAdmin(uContext.admin)
    }
  }

  const getToken = async (e) => {
    let token = await authService.getToken(user?.uid)
    navigator.clipboard.writeText(token)
    alertify.success('Copied user token to clipboard')
  }

  const copyToClipboard = (e) => {
    e.target.focus()
    navigator.clipboard.writeText(user?.email)
    alertify.success('Copied user email to clipboard')
  }

  const setAdmin = () => {
    alertify.confirm(
      'Update Admin',
      `change admin status of ${user.email}?`,
      () => {
        authService.makeAuthAdmin(user.uid).then((d) => {
          if (!d.success) {
            alertify.error(d.error)
          } else {
            alertify.success('Admin Access granted')
            fetchData()
          }
        })
      },
      () => {},
    )
  }

  const setDisabled = () => {
    alertify.confirm(
      'Update status',
      `change overall status of ${user.email}?`,
      () => {
        authService.disableUser(user.uid, !user.disabled).then((d) => {
          if (!d.success) {
            alertify.error(d.error)
          } else {
            alertify.success(`User is now ${!user.disabled ? 'disabled' : 'enabled'}`)
            fetchData()
          }
        })
      },
      () => {},
    )
  }

  const toggleContactSupport = () => {
    alertify.confirm(
      'Contact Support',
      `Toggle requirement of having user contact support?`,
      () => {
        authService.toggleRequireContactSupport(user.uid).then((d) => {
          if (!d.success) {
            alertify.error(d.error)
          } else {
            let temp = !user.contactSupport
            if (temp) {
              alertify.success(`User is now required to contact support before signing into Franchisee Portal`)
            } else {
              alertify.success(`User is not required to contact support before signing into Franchisee Portal`)
            }
            fetchData()
          }
        })
      },
      () => {},
    )
  }

  const updateUserFromGoogle = () => {
    authService.updateUserFromGWorkspace(user.uid).then((d) => {
      if (!d.success) {
        alertify.error(d.error)
      } else {
        fetchData()
      }
    })
  }

  return (
    <div className='w-full flex flex-row justify-center items-center'>
      <div className='w-full flex flex-col items-center justify-end space-y-2 space-x-2 md:flex-row'>
        <div className='w-full px-2 py-4 flex flex-col justify-center md:flex-row'>
          <div className='w-full max-w-xl flex flex-col mr-2 md:w-1/2'>
            <div className='text-center bg-white rounded-lg shadow-xl mb-2'>
              <div className='flex-1 flex flex-col p-8'>
                {user?.photoURL ? <img className='h-32 w-32 flex-shrink-0 mx-auto rounded-full' src={user?.photoURL} alt='user profile' /> : <div className='animate-pulse w-32 h-32 bg-gray-300 rounded-full flex-shrink-0 mx-auto' />}
                <h3 className='mt-6 text-gray-900 text-lg leading-5 font-medium'>{user?.displayName}</h3>
                <dl className='mt-1 flex-grow flex flex-col justify-between'>
                  {user?.email ? <dd className='text-gray-500 text-sm leading-5'>{user?.email}</dd> : <dd className='animate-pulse rounded-full flex-shrink-0 text-gray-300 bg-gray-300 text-sm leading-5'>email</dd>}
                  {isAdmin ? (
                    <dd>
                      <span className='text-gray-900 text-sm pr-2'>Admin</span>
                      <span
                        role='checkbox'
                        onClick={() => setAdmin()}
                        tabIndex='0'
                        aria-checked='false'
                        className={`${user.admin ? 'bg-blue-400' : 'bg-gray-200'} mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
                        <span aria-hidden='true' className={`${user.admin ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
                      </span>
                    </dd>
                  ) : (
                    <dd>
                      <span className='text-gray-300 text-sm pr-2'>Admin</span>
                      <span className=' mt-2 bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline'>
                        <span aria-hidden='true' className='translate-x-0inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200'></span>
                      </span>
                    </dd>
                  )}
                  {user ? (
                    <dd>
                      <span className='text-gray-900 text-sm pr-2'>Disabled</span>
                      <span
                        role='checkbox'
                        onClick={() => setDisabled()}
                        tabIndex='0'
                        aria-checked='false'
                        className={`${user.disabled ? 'bg-blue-400' : 'bg-gray-200'} mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
                        <span aria-hidden='true' className={`${user.disabled ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
                      </span>
                    </dd>
                  ) : (
                    <dd>
                      <span className='text-gray-300 text-sm pr-2'>Disabled</span>
                      <span className=' mt-2 bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline'>
                        <span aria-hidden='true' className='translate-x-0inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200'></span>
                      </span>
                    </dd>
                  )}
                  {user ? (
                    <dd>
                      <span className='text-gray-900 text-sm pr-2'>Must Contact Support</span>
                      <span
                        role='checkbox'
                        onClick={() => toggleContactSupport()}
                        tabIndex='0'
                        aria-checked='false'
                        className={`${user.contactSupport ? 'bg-blue-400' : 'bg-gray-200'} mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
                        <span aria-hidden='true' className={`${user.contactSupport ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
                      </span>
                    </dd>
                  ) : (
                    <dd>
                      <span className='text-gray-300 text-sm pr-2'>Must Contact Support</span>
                      <span className=' mt-2 bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline'>
                        <span aria-hidden='true' className='translate-x-0inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200'></span>
                      </span>
                    </dd>
                  )}
                </dl>
              </div>
              <div className='w-full flex flex-row mt-2'>
                <div className='w-1/4 flex flex-col' />
                {process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'STAGING' || process.env.REACT_APP_ENV === 'PRODUCTION' ? (
                  <div className='w-full flex flex-col ml-6 mr-6 text-center mb-4'>
                    <button onClick={() => updateUserFromGoogle()} className='bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-full inline-flex items-center ml-auto mr-auto focus:outline-none'>
                      <img className='fill-current w-6 h-6 mr-2' src={GLogo} alt='GoogleLogo' />
                      Update profile with Google
                    </button>
                  </div>
                ) : (
                  ''
                )}
                <div className='w-1/4 flex flex-col' />
              </div>
              <div className='border-t border-gray-200'>
                <div className='flex'>
                  <button className='w-0 flex-1 flex border-r border-gray-200 cursor-pointer' onClick={copyToClipboard}>
                    <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
                      <FontAwesomeIcon icon={faEnvelopeOpenText} color='gray' />
                      <span className='ml-3'>Email</span>
                    </div>
                  </button>
                  {isAdmin && (process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'STAGING' || process.env.REACT_APP_ENV === 'PRODUCTION') ? (
                    <button className='w-0 flex-1 flex border-r border-gray-200 cursor-pointer' onClick={() => getToken()}>
                      <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
                        <FontAwesomeIcon icon={faCoins} color='gray' />
                        <span className='ml-3'>Login Token</span>
                      </div>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <RolesCard header='Unique Roles' uid={user?.uid} groups={groups} />
            </div>
            <div className='mb-2'>
              <UserAccessCard header='Locations' uid={user?.uid} />
            </div>
          </div>
          <div className='w-full max-w-xl md:w-1/2'>
            <div className='mb-2'>
              <GroupsCard header='Groups' uid={user?.uid} setGroups={setGroups} />
            </div>
            <div className='mb-2'>
              <PermissionsCard header='Unique Permissions' uid={user?.uid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User
