import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import alertify from 'alertifyjs'
import { AuthContext } from 'services/context/AuthContext'

const DescCard = (props) => {
  const [groupName, setGroupName] = useState('')
  const [groupDesc, setGroupDesc] = useState('')
  const [loading, setLoading] = useState(false)
  const [isAuthAdmin, setIsAuthAdmin] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  useEffect(() => {
    setIsAuthAdmin(uContext.hasGroup('auth-admin'))
  }, [uContext])

  useEffect(() => {
    setGroupName(props.groupName)
    setGroupDesc(props.groupDesc)
  }, [props.groupDesc, props.permissions, props.groupName])

  const updateDesc = () => {
    setLoading(true)
    authService.updateGroupDesc(groupName, groupDesc).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Group description updated')
      }
    })
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-auto mr-auto mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>Description</h3>
          </div>
        </div>
      </div>
      <div className='bg-white px-2 shadow-xl rounded-b-lg'>
        <div className='opacity-100 w-full flex items-center justify-between p-6 space-x-6'>
          <textarea
            className={`w-full rounded-md py-1 px-2 resize-none text-gray-600 ${!isAuthAdmin ? 'bg-gray-300 cursor-not-allowed opacity-75' : 'bg-gray-100 border border-gray-300'}`}
            placeholder='Group Description'
            type='text'
            disabled={!isAuthAdmin}
            value={groupDesc || ''}
            onChange={(e) => setGroupDesc(e.target.value)}></textarea>
          <button
            onClick={updateDesc}
            disabled={groupDesc?.length <= 0 || !groupDesc || loading || !isAuthAdmin}
            className={`text-white font-bold py-2 px-4 rounded-md focus:outline-none justify-center ${!groupDesc || !isAuthAdmin ? 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' /> : 'Update'}
          </button>
        </div>
      </div>
    </>
  )
}

export default DescCard
