import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
const SearchBar = (props) => {
  return (
    <div className='w-full flex flex-row relative items-center'>
      <input
        className='w-full h-10 appearance-none border bg-gray-200 active:bg-white focus:outline-none focus:shadow-outline-indigo focus:bg-white rounded-md py-2 px-3 text-gray-700 leading-tight'
        placeholder='Search'
        defaultValue={''}
        onChange={(e) => {
          props.handleSearchChange(e.target.value)
          props.setPageNumber(0)
        }}
        type='text'
      />
      {props.searching ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md absolute z-20 right-3 align-middle' /> : <p className='text-gray-500 text-md absolute z-20 right-3 align-middle'>{props.totalCount} Results</p>}
    </div>
  )
}

export default SearchBar
