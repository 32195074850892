import React, { useContext, useEffect, useState } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchApi } from 'services/api'
import SearchBar from 'pages/components/SearchBar'
import { NavLink } from 'react-router-dom'
import logo from '../../../../src/assets/img/logo_dark.png'
import PaginatedRows from './PaginatedRows'
import { AuthContext } from 'services/context/AuthContext'

const UsersCard = (props) => {
  const [users, setUsers] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [searchTimeout, setSearchTimeout] = useState(0)

  const [total, setTotal] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)
  const uContext = useContext(AuthContext)

  const searchService = new SearchApi(uContext.user)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      let response = await searchService.pagedUserSearchByGroup(searchTerm, props.group, pageSize, pageNumber)
      setUsers(response.users)
      setTotal(response.totalResults)
      setTotalPages(response.totalPages)

      setIsLoading(false)
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(
      setTimeout(() => {
        if (props.group) {
          fetchData()
        }
      }, 500),
    )
    // eslint-disable-next-line
  }, [searchTerm, pageSize, pageNumber, props.group])

  const changePageSize = (size) => {
    setPageNumber(0)
    setPageSize(size)
  }

  const nextPage = async () => {
    setPageNumber(pageNumber + 1)
  }

  const prevPage = async () => {
    setPageNumber(pageNumber - 1)
  }

  return (
    <>
      <PaginatedRows
        changePageSize={changePageSize}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalPages={totalPages}
        nextPage={nextPage}
        prevPage={prevPage}
        rows={users}
        top={
          <div className='bg-gray-100 px-4 py-3 rounded-t-lg border border-gray-200'>
            <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
              <div className='ml-4 mt-2 flex flex-col justify-start sm:flex-row sm:justify-between items-center w-full'>
                <h3 className='text-xl font-medium text-gray-600 leading-tight flex-none'>Users</h3>
                <div className='flex-1 md:mr-8 md:ml-8'>
                  <SearchBar searchTerm={searchTerm} handleSearchChange={setSearchTerm} setPageNumber={setPageNumber} searching={isLoading} totalCount={total} />
                </div>
              </div>
            </div>
          </div>
        }
        elements={
          !users || isLoading ? (
            <div className='w-full py-16 text-center'>
              <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600' size='4x' />
            </div>
          ) : (
            users?.map((user) => {
              return (
                <div key={user.email} className='w-full flex items-center justify-between p-3 space-x-3 mt-2 rounded-lg border-opacity-50 shadow hover:shadow-lg'>
                  <img className='w-12 h-12 bg-gray-300 rounded-full flex-shrink-0' src={user?.photoURL !== '' ? user.photoURL : logo} alt='' />
                  <div className='flex-1 truncate'>
                    <NavLink to={'/user/' + user.uid} className='flex flex-col justify-start ml-3'>
                      <h3 className='text-gray-900 text-md leading-5 font-medium truncate'>{user.displayName ?? 'No Name'}</h3>
                      <p className='mt-1 text-gray-500 text-sm leading-5 truncate'>{user.email}</p>
                    </NavLink>
                  </div>
                </div>
              )
            })
          )
        }></PaginatedRows>
    </>
  )
}

export default UsersCard
