import React, { useEffect, useState, useContext } from 'react'
import CreateUserAside from './components/CreateUserAside'
import User from './components/User'
import FakeUser from './components/FakeUser'
import SearchBar from 'pages/components/SearchBar'
import { SearchApi } from 'services/api'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, collection, getDocs } from 'firebase/firestore'

const Users = (props) => {
  const [users, setUsers] = useState()
  const [pageSize, setPageSize] = useState(16)
  const [pageNumber, setPageNumber] = useState(0)
  const [showCreateBar, setShowCreateBar] = useState(false)
  const [allGroups, setAllGroups] = useState([])
  const [total, setTotal] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimeout, setSearchTimeout] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isAdmin, setAdmin] = useState(false)
  const [onlyActiveUsers, setOnlyActiveUsers] = useState(true)
  const uContext = useContext(AuthContext)
  const db = getFirestore(uContext.firebaseApp)

  const searchService = new SearchApi(uContext.user)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      let response = await searchService.pagedUserSearch(searchTerm, pageSize, pageNumber, onlyActiveUsers)
      setUsers(response.users)
      setTotal(response.totalResults)
      setTotalPages(response.totalPages)
      setIsLoading(false)
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    setSearchTimeout(
      setTimeout(() => {
        fetchData()
      }, 500),
    )

    async function fetchGroups() {
      const data = await getDocs(collection(db, 'groups'))
      const g = []
      data.forEach((d) => {
        g.push(d.data())
      })
      setAllGroups(g)
    }

    if (allGroups.length === 0) {
      fetchGroups()
    }

    setAdmin(uContext.admin)
    // eslint-disable-next-line
  }, [pageSize, pageNumber, searchTerm, onlyActiveUsers])

  const createUser = () => {
    setShowCreateBar(true)
  }

  const changePageSize = (size) => {
    setPageNumber(0)
    setPageSize(size)
  }

  const nextPage = async () => {
    setPageNumber(pageNumber + 1)
  }

  const prevPage = async () => {
    setPageNumber(pageNumber - 1)
  }
  const fakeUsers = [0, 1, 2, 3, 4, 5, 6, 7]

  const toggleOnlyActive = async () => {
    setOnlyActiveUsers(!onlyActiveUsers)
  }

  return (
    <div className='flex flex-row justify-center'>
      <div className='w-full max-w-screen-xl px-2 py-4'>
        <CreateUserAside doShowBar={showCreateBar} setShowBar={setShowCreateBar} allGroups={allGroups} />
        <div className='flex flex-wrap -mx-2 -ml-2 -mr-2'>
          <div className='w-full px-2 mb-2 h-full'>
            <div className='px-2 pt-2 pb-4 bg-gray-100 rounded-md shadow-xl'>
              <div className='px-4 py-5 sm:p-6'>
                <div className='flex flex-col justify-start sm:flex-row sm:justify-between items-center'>
                  <div className='pl-4 text-gray-700 text-3xl leading-tight flex-none'>Users</div>
                  <div className='mt-2 flex-1 md:mr-2 md:ml-8'>
                    <SearchBar searchTerm={searchTerm} handleSearchChange={setSearchTerm} setPageNumber={setPageNumber} searching={isLoading} totalCount={total} />
                  </div>
                  <div className='mt-2 mr-8'>
                    <button onClick={toggleOnlyActive} className={(onlyActiveUsers ? '' : 'opacity-50 ') + 'bg-purple-600 hover:bg-purple-500 w-full text-white font-medium text-md leading-5 py-2 px-4 h-10 rounded-md focus:outline-none'}>
                      Only Active
                    </button>
                  </div>
                  <div className='mt-2'>
                    <button onClick={createUser} className='bg-blue-600 hover:bg-blue-500 w-full text-white font-medium text-md leading-5 py-2 px-4 h-10 rounded-md focus:outline-none'>
                      Create User
                    </button>
                  </div>
                </div>
                <nav className='mt-8 border-t border-gray-400 px-4 flex items-start justify-between sm:px-0'>
                  <div
                    onClick={() => {
                      prevPage()
                    }}
                    className={`w-0 flex-1 flex cursor-pointer`}>
                    <div
                      className={`${
                        pageNumber === 0 && 'hidden'
                      } -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150`}>
                      <svg className='mr-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                        <path fillRule='evenodd' d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z' clipRule='evenodd' />
                      </svg>
                      Previous
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div className='hidden md:flex'>
                      <div
                        onClick={() => {
                          changePageSize(8)
                        }}
                        className={`${
                          pageSize === 8 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                        } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                        8
                      </div>
                      <div
                        onClick={() => {
                          changePageSize(16)
                        }}
                        className={`${
                          pageSize === 16 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                        } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                        16
                      </div>
                      <div
                        onClick={() => {
                          changePageSize(24)
                        }}
                        className={`${
                          pageSize === 24 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                        } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                        24
                      </div>
                    </div>
                    <div className='flex justify-center text-gray-500 font-medium pt-3 text-sm'>
                      {pageNumber + 1} / {totalPages}
                    </div>
                  </div>
                  <div className='w-0 flex-1 flex justify-end'>
                    <div
                      onClick={() => {
                        nextPage()
                      }}
                      className={`${
                        (pageNumber + 1 === totalPages || users?.length === 0) && 'hidden'
                      } cursor-pointer -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150`}>
                      Next
                      <svg className='ml-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                        <path fillRule='evenodd' d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z' clipRule='evenodd' />
                      </svg>
                    </div>
                  </div>
                </nav>
                <div className='w-full rounded'>
                  <div className='w-full border-b border-gray-200 h-full'>
                    <ul className='w-full grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 py-4 px-8 h-full'>{!users ? fakeUsers.map((index) => <FakeUser key={index} />) : users.map((user, index) => <User key={user.uid} user={user} allGroups={allGroups} isAdmin={isAdmin} />)}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users
