import React, { useState } from 'react'

const Modal = (props) => {
  const [groupName, setGroupName] = useState('')
  const [groupDesc, setGroupDesc] = useState('')

  const modalSyle = {
    backgroundColor: 'rgba(0,0,0,0.5)',
  }

  return (
    <>
      <div className='fixed flex flex-col inset-0 justify-center items-center mb-15' style={modalSyle} onClick={() => props.closeGroupModal()}>
        <div className='flex flex-col justify-center items-center border rounded-md p-3 bg-white w-96' onClick={(e) => e.stopPropagation()}>
          <h2 className='text-xl'>Create Group</h2>
          <div>
            <input className='bg-gray-100 text-gray-700 shadow focus:outline-none appearance-none border rounded-md py-2 px-3 mt-2 w-full' type='text' placeholder='name' value={groupName} onChange={(e) => setGroupName(e.target.value)}></input>
            <textarea className='bg-gray-100 text-gray-700 shadow focus:outline-none appearance-none border rounded-md py-2 px-3 mt-2 w-full' type='text' placeholder='description' value={groupDesc} onChange={(e) => setGroupDesc(e.target.value)}></textarea>
          </div>
          <div className='mt-2'>
            <button
              onClick={() => props.createGroup(groupName, groupDesc)}
              className={`${props.isAuthAdmin && groupName?.length > 0 ? 'bg-blue-600 border border-blue-700 hover:border-blue-500 hover:bg-blue-500' : 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75'} shadow text-white font-bold py-2 px-4 rounded-md focus:outline-none mr-2`}
              disabled={groupName?.length <= 0 || !props.isAuthAdmin}>
              Submit
            </button>
            <button className='bg-gray-400 border border-gray-400 hover:border-gray-300 hover:bg-gray-300 shadow text-white font-bold py-2 px-4 rounded-md focus:outline-none' onClick={() => props.closeGroupModal()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
