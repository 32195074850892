import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import AdminCard from './components/AdminCard'
import QuickCreate from './components/QuickCreate'
import GroupEdit from '../components/GroupEdit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faCoins } from '@fortawesome/free-solid-svg-icons'
import alertify from 'alertifyjs'
import { AuthContext } from 'services/context/AuthContext'

const Dashboard = (props) => {
  const [groupsModal, setGroupsModal] = useState(false)
  const [editGroup, setEditGroup] = useState(null)
  const uContext = useContext(AuthContext)
  const [isAuthAdmin, setIsAuthAdmin] = useState(false)

  const hideGroupsModal = () => {
    setEditGroup(null)
    setGroupsModal(false)
  }

  useEffect(() => {
    setIsAuthAdmin(uContext.hasGroup('auth-admin'))
  }, [uContext])

  useEffect(() => {
    let data = []
    if (uContext.inGroups) {
      uContext.inGroups.forEach((g) => {
        data.push({ text: g.name })
      })
    }
  }, [uContext])

  const copyToClipboard = async (e) => {
    e.target.focus()
    navigator.clipboard.writeText(await uContext.user.getIdToken())
    alertify.success('Token was copied to your clipboard')
  }

  return (
    <div className='w-full flex flex-row justify-center items-center'>
      <div className='w-full flex flex-col items-center justify-end space-y-2 space-x-2 md:flex-row'>
        <div className='w-full px-2 py-4 flex flex-col justify-center md:flex-row'>
          <div className='w-full max-w-xl flex flex-col mr-2 md:w-1/3'>
            <div className='text-center bg-white rounded-lg shadow'>
              <div className='flex-1 flex flex-col p-8'>
                <img className='h-32 w-32 flex-shrink-0 mx-auto bg-black rounded-full' src={uContext.user.photoURL} alt='user profile' />
                <h3 className='mt-6 text-gray-900 text-lg leading-5 font-medium'>{uContext.user.displayName}</h3>
                <dl className='mt-1 flex-grow flex flex-col justify-between'>
                  <dd className='text-gray-500 text-sm leading-5'>{uContext.user.email}</dd>
                  <dd className='mt-3 overflow-hidden break-normal'>
                    {uContext.admin && <span className='px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full mr-1'>ADMIN</span>}
                    {uContext.inGroups?.map((group, index) => (
                      <span className='px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-blue-100 rounded-full mr-1' key={group.name}>
                        {group.name.toUpperCase()}
                      </span>
                    ))}
                  </dd>
                </dl>
              </div>
              <div className='border-t border-gray-200'>
                <div className='flex'>
                  <button className='-ml-px w-0 flex-1 flex cursor-pointer' onClick={copyToClipboard}>
                    <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
                      <FontAwesomeIcon icon={faCoins} color='gray' />
                      <span className='ml-3'>Access Token</span>
                    </div>
                  </button>
                  <NavLink to={'/user/' + uContext.user.uid} className='-ml-px w-0 flex-1 flex cursor-pointer'>
                    <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
                      <FontAwesomeIcon icon={faAddressCard} color='gray' />
                      <span className='ml-3'>User Details</span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full max-w-xl md:w-1/3 md:pt-2'>
            <div className='px-2 mb-2'>
              <AdminCard isAuthAdmin={isAuthAdmin} header='Make Auth Admin' />
            </div>
            <div className='px-2 mb-2'>
              <QuickCreate isAuthAdmin={isAuthAdmin} />
            </div>
            <div>
              <GroupEdit showModal={groupsModal} hideModal={hideGroupsModal} group={editGroup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
