import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Shell from '../components/layouts/default/Shell'
import Login from '../pages/login/Login'
import withAuthUserProvider from '../services/firebase/AuthUserContext'
import FSLogo from '../assets/img/PBLogo.png'
import { AuthContext } from 'services/context/AuthContext'

const AuthRouteImplemented = (props) => {
  const uContext = useContext(AuthContext)

  return (
    <>
      {uContext?.user ? (
        <Switch>
          {!uContext ? (
            <>
              <Redirect to='/login' />
              <Route path='/login' component={Login} />
            </>
          ) : (
            <>
              <Shell />
            </>
          )}
        </Switch>
      ) : (
        <>
          <div className={`flex flex-row pt-40 m-auto justify-center bg-black h-screen w-full`}>
            <div className='flex flex-row m-auto'>
              <div className={`flex justify-between rotate-0 animate-bounce`}>
                <img className='h-24 w-auto mb-5' src={FSLogo} alt='FSLogo' />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const AuthRouteComponent = AuthRouteImplemented

const AuthRoute = (props) => {
  return (
    <>
      <AuthRouteComponent />
    </>
  )
}

export default withAuthUserProvider(AuthRoute)
