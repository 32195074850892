import React, { useState, useContext } from 'react'
import alertify from 'alertifyjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import NewUserList from './NewUserList'
import GroupsList from './GroupsList'
import { AuthContext } from 'services/context/AuthContext'

const CopyNewUsers = (props) => {
  const [emails, setEmails] = useState([])
  const [importing, setImporting] = useState(false)
  const [fetchingGroups, setFetchingGroups] = useState(false)
  const [selectedUser, setSelectedUser] = useState('')
  const [groups, setGroups] = useState([])
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  const handleEmailsChange = (changedEmails) => {
    setEmails(changedEmails)
  }

  const getUsersGroups = async () => {
    setFetchingGroups(true)

    try {
      let response = await authService.getUserInfoByEmail(selectedUser.toLocaleLowerCase())

      setFetchingGroups(false)

      let g = response.inGroups.map((gp) => gp.name)
      setGroups(g)
    } catch (err) {
      console.error('There was an error retrieving the user by email.')
    }
  }

  const importUsers = () => {
    let users = emails.filter((v, i, a) => a.findIndex((o) => ['email', 'name'].every((k) => o[k] === v[k])) === i)
    let userGroups = [...new Set(groups)]

    // Preliminary error checks
    if (users.filter((u) => u?.email === '').length > 0) {
      alertify.error('There are blank emails')
      return
    }
    if (userGroups.filter((g) => g === '').length > 0) {
      alertify.error('There are blank group selections')
      return
    }

    setImporting(true)
    authService.importUsers(users, userGroups).then((data) => {
      setImporting(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Successfully imported users')
      }
    })
  }

  return (
    <div className='w-full'>
      <div className=''>
        <NewUserList updateParentEmails={handleEmailsChange} />
        <div className='mt-6 w-full'>
          <GroupsList groups={groups} />
        </div>
        <div className='mt-6 w-full'>
          <div className='relative mx-3 mb-5'>
            <div className='absolute top-0 -mt-3 ml-4 text-xs leading-5 font-medium text-gray-400 bg-gray-100 w-max-content px-2 select-none'>Copy Groups From</div>
            <input className='appearance-none shadow-none border-2 border-gray-300 px-2 pt-3 pb-2 rounded-md w-full text-gray-500 bg-transparent text-md leading-5 font-medium focus:outline-none' type='text' placeholder='Email' onChange={(e) => setSelectedUser(e.target.value)} />
          </div>
          <button disabled={emails.length === 0} onClick={getUsersGroups} className={`text-white py-2 px-4 w-full focus:outline-none flex items-baseline ${emails.length === 0 ? 'bg-gray-400 pointer-events-none' : 'bg-blue-500 hover:bg-blue-600 '}`}>
            {fetchingGroups ? (
              <div className='mr-2'>
                <FontAwesomeIcon className={`ml-1 animate-spin fill-current text-blue-200 opacity-60`} size='lg' icon={faCircleNotch} />
              </div>
            ) : (
              ''
            )}
            <div className=''>Fetch Groups</div>
          </button>
        </div>
      </div>
      <button disabled={emails.length === 0} onClick={importUsers} className={`w-full h-10 mt-8  text-white font-medium leading-5 text-md py-2 px-4 focus:outline-none ${emails.length === 0 ? 'bg-gray-400 pointer-events-none' : 'bg-green-500 hover:bg-green-600'}`}>
        <div className='float-left'>Import Users</div>
        {importing ? (
          <div className='float-right'>
            <FontAwesomeIcon className={`ml-1 animate-spin fill-current text-blue-700 opacity-60`} size='lg' icon={faCircleNotch} />
          </div>
        ) : (
          ''
        )}
      </button>
    </div>
  )
}

export default CopyNewUsers
