import React, { useEffect, useState, useCallback, useRef, useContext } from 'react'
import { faMinusCircle, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import alertify from 'alertifyjs'
import Select from 'react-select'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, getDocs, collection } from 'firebase/firestore'

const UserAccessCard = (props) => {
  const [locations, setLocations] = useState(null)
  const [brands, setBrands] = useState([])
  const [centers, setCenters] = useState([])
  const [currentBrand, setCurrentBrand] = useState()
  const [currentCenter, setCurrentCenter] = useState()
  const [currentAccessLevel, setCurrentAccessLevel] = useState()
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi
  const db = getFirestore(uContext.firebaseApp)

  const brandRef = useRef()
  const locationRef = useRef()
  const levelRef = useRef()

  const availableAccessLevels = [
    { label: 'Owner', value: 'owner' },
    { label: 'Admin', value: 'admin' },
    { label: 'Manager', value: 'manager' },
    { label: 'Employee', value: 'employee' },
  ]

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
  }

  const updateCenterList = useCallback(
    async (cBrand, brandsArray) => {
      setCurrentBrand(cBrand)
      const brandCollection = (brand) => {
        return brandsArray.find((b) => b.label === brand)?.locationsCollection || 'center_info'
      }
      setCurrentCenter()
      setCurrentAccessLevel()
      locationRef.current.setValue('')
      levelRef.current.setValue('')
      const col = brandCollection(cBrand)
      let centerCollection = await getDocs(collection(db, col))
      let c = centerCollection.docs.map((doc) => {
        return { label: doc.id, value: doc.id }
      })
     
      setCenters(c)
    },
    [db],
  )

  useEffect(
    function getLocations() {
      async function fetchData() {
        const data = await authService.getUser(props.uid)
        setLocations(data.locations ? data.locations : [])
        let brandsCollection = await getDocs(collection(db, 'brands'))
        let b = brandsCollection.docs.map((doc) => {
          const docObject = doc.data()
          return { label: doc.id, value: docObject.name, locationsCollection: docObject.locationList }
        })
        setBrands(b)

      }

      if (props.uid) {
        fetchData()
      }
    },
    [props.uid, updateCenterList, currentBrand, authService, db],
  )

  const removeLocation = async (location) => {
    let data = await authService.removeLocation(props.uid, location)
    if (data.success) {
      let newLocations = locations
      newLocations = newLocations.filter((l) => {
        if (l.locationId === location.locationId && l.level.toLowerCase() === location.level.toLowerCase() && l.brand === location.brand) {
          return false
        } else {
          return true
        }
      })

      setLocations(newLocations)
      clear()
      alertify.success('Removed Access')
    } else {
      alertify.error(data.error)
    }
  }

  const addLocation = async () => {
    if(!currentBrand){
      alertify.error("Select a brand")
    }else if(!currentCenter){
      alertify.error("Select a location")
    }else if(!currentAccessLevel){
      alertify.error("Select a access level")
    }else{
    let data = await authService.addLocation(props.uid, {
      locationId: currentCenter,
      level: currentAccessLevel,
      brand: currentBrand,
    })
    if (data.success) {
      let newLocations = locations
      newLocations.push({ locationId: currentCenter, level: currentAccessLevel, brand: currentBrand })
      setLocations(newLocations)
      clear()
      alertify.success('Added Location')
    } else {
      alertify.error(data.error)
    }
    }
  }

  const clear = () => {
    setCurrentCenter()
    setCurrentAccessLevel()
    setCurrentBrand()
    brandRef.current.setValue('')
    locationRef.current.setValue('')
    levelRef.current.setValue('')
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-4 mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>{props.header}</h3>
          </div>
        </div>
      </div>
      <div className='bg-white px-2 shadow-xl rounded-b-lg'>
        <div className={`opacity-100 w-full flex items-center justify-between p-6 space-x-6`}>
          <div className='flex-1'>
            <div className='flex flex-col space-y-2 font-medium text-md '>
              {!locations ? (
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
              ) : (
                <div>
                  <table className='w-full'>
                    <thead className='border-gray-300 text-gray-500 text-md border-b text-left'>
                      <tr>
                        <th>Brand</th>
                        <th>Location</th>
                        <th>Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locations.map((a) => {
                        return (
                          <tr key={a.locationId + a.level} className='border-b border-gray-300 text-gray-500 text-sm leading-5 font-medium truncate'>
                            <td className='w-3/12'>{a.brand}</td>
                            <td className='w-3/12'>{a.locationId}</td>
                            <td className='w-4/12'>{a.level}</td>
                            <td className='w-2/12'>
                              <button className='focus:outline-none float-right'>
                                <FontAwesomeIcon size='2x' icon={faMinusCircle} onClick={() => removeLocation(a)} />
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <h3 className='mt-4 text-gray-500'>Add Access</h3>
                  <div className='mt-2 flex flex-row w-full space-x-4 text-gray-500'>
                    <div className='w-3/12'>
                      <Select ref={brandRef} placeholder='Brand' options={brands} onChange={(option) => updateCenterList(option.value, brands)} isSearchable={false} menuPlacement='top' styles={customStyles} />
                    </div>
                    <div className='w-4/12'>
                      <Select ref={locationRef} isDisabled={currentBrand ? false : true} placeholder='Location' options={centers} onChange={(option) => setCurrentCenter(option.value)} menuPlacement='top' />
                    </div>
                    <div className='w-4/12'>
                      <Select ref={levelRef} isDisabled={currentCenter ? false : true} placeholder='Level' options={availableAccessLevels} onChange={(option) => setCurrentAccessLevel(option.value)} isSearchable={false} menuPlacement='top' styles={customStyles} />
                    </div>
                    <div className='w-1/12 focus:outline-none'>
                      <button className='focus:outline-none float-right'>
                        <FontAwesomeIcon size='2x' icon={faPlusCircle} onClick={() => addLocation()} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserAccessCard
