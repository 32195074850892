import React from 'react'
import { withRouter } from 'react-router-dom'

const pageWrapper = (props) => {
  return (
    <div>
      <div className='shadow-sm bg-white xs:h-0 sm:h-12 text-lg px-5 pt-3 text-blue-500'>Dashboard</div>
      <div>{props.children}</div>
    </div>
  )
}
export const PageWrapper = withRouter(pageWrapper)
