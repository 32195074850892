import React from 'react'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import Select from 'react-select'

const EditGroupsList = (props) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
  }
  const groupOptions = props.allGroups.map((g) => ({ label: g.name, value: g.name }))

  const updateGroups = (options, meta) => {
    props.updateParentGroups(options.map((o) => o.value))
  }

  return (
    <div>
      <h2 className='mx-3 flex flex-row font-medium text-slate-900'>Groups</h2>
      <hr className='m-3 mt-1 border-1 border-gray-800' />
      <div className='mb-2 mx-3 flex flex-row'>
        {props.allGroups.length === 0 ? (
          <div className='py-2'>
            <LoadIndicator className='justify-center' height={25} width={25} />
          </div>
        ) : (
          <>
            <Select placeholder='Select Groups' options={groupOptions} onChange={updateGroups} closeMenuOnSelect={false} isMulti menuPlacement='auto' styles={customStyles} />
          </>
        )}
      </div>
    </div>
  )
}

export default EditGroupsList
