import React, { useEffect, useState, useContext } from 'react'
import alertify from 'alertifyjs'
import ExpandableRow from './components/ExpandableRow'
import PaginatedRows from 'pages/components/PaginatedRows'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, collection, query, orderBy, getDocs, limit, limitToLast, startAfter, endBefore } from 'firebase/firestore'

const Roles = () => {
  const [roles, setRoles] = useState(null)
  const [newRole, setNewRole] = useState('')
  const [pageSize, setPageSize] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)
  const uContext = useContext(AuthContext)
  const db = getFirestore(uContext.firebaseApp)

  const [isAuthAdmin, setIsAuthAdmin] = useState(false)

  const authService = uContext.AuthApi

  const getRoles = async (next, prev) => {
    let roles = await getDocs(query(collection(db, 'roles'), limit(pageSize), orderBy('name')))
    if (next) {
      roles = await getDocs(query(collection(db, 'roles'), orderBy('name'), startAfter(next), limit(pageSize)))
    } else if (prev) {
      roles = await getDocs(query(collection(db, 'roles'), orderBy('name'), endBefore(prev), limitToLast(pageSize)))
    }
    let r = roles.docs.map((x) => x.data())
    setRoles(r)
  }

  useEffect(() => {
    async function fetchData() {
      await getRoles()
    }
    fetchData()
    // eslint-disable-next-line
  }, [pageSize])

  useEffect(() => {
    setIsAuthAdmin(uContext.hasGroup('auth-admin'))
  }, [uContext])

  const deleteRole = (name) => {
    alertify.confirm(
      'Delete Role',
      'Do you want to delete role: ' + name,
      function () {
        authService.deleteRole(name).then((data) => {
          if (data.success === false) {
            alertify.error(data.error)
          } else {
            alertify.success('Deleted role: ' + name)
            // Just a refreshRoles should be necessary here.  apollo caching is causing an issue though
            setPageNumber(0)
          }
        })
      },
      function () {
        return
      },
    )
  }

  const createRole = async () => {
    const data = await authService.createRole(newRole, [])
    if (data.success === false) {
      alertify.error(data.error)
    } else {
      alertify.success('Created Role: ' + newRole)
      setPageNumber(0)
      await getRoles(null, null)
    }
  }

  const changePageSize = (size) => {
    setPageNumber(0)
    setPageSize(size)
  }

  const nextPage = async () => {
    setPageNumber(pageNumber + 1)
    let val = roles[roles.length - 1]?.name
    await getRoles(val)
    // if (!val) {
    //   setFn(
    //     props.firebase
    //       .firestore()
    //       .collection("roles")
    //       .orderBy("name")
    //       .limit(pageSize)
    //       .get()
    //   )
    // } else {
    //   setFn(
    //     props.firebase
    //       .firestore()
    //       .collection("roles")
    //       .orderBy("name")
    //       .startAfter(val)
    //       .limit(pageSize)
    //       .get()
    //   )
    // }
  }

  const prevPage = async () => {
    setPageNumber(pageNumber - 1)
    await getRoles(null, roles[0]?.name)
    // let val = roles[0]?.name
    // if (!val) {
    //   setFn(
    //     props.firebase
    //       .firestore()
    //       .collection("roles")
    //       .orderBy("name")
    //       .limitToLast(pageSize)
    //       .get()
    //   )
    // } else {
    //   setFn(
    //     props.firebase
    //       .firestore()
    //       .collection("roles")
    //       .orderBy("name")
    //       .endBefore(val)
    //       .limitToLast(pageSize)
    //       .get()
    //   )
    // }
  }

  return (
    <PaginatedRows
      key='groupsPage'
      changePageSize={changePageSize}
      pageNumber={pageNumber}
      pageSize={pageSize}
      nextPage={nextPage}
      prevPage={prevPage}
      rows={roles}
      top={
        <div className='w-full'>
          <div className=' px-1 text-gray-600 text-2xl text-left float-left'>Roles</div>
          <button
            onClick={async () => await createRole()}
            className={`${isAuthAdmin ? 'bg-blue-600 border border-blue-700 hover:border-blue-500 hover:bg-blue-500' : 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75'} shadow text-white font-bold py-2 px-4 rounded-r focus:outline-none float-right`}
            disabled={newRole.length <= 0 || !isAuthAdmin}>
            Create Role
          </button>
          <input
            className={`${isAuthAdmin ? 'bg-gray-100 text-gray-700' : 'bg-gray-300 placeholder-white cursor-not-allowed opacity-75'} shadow border focus:outline-none rounded-l w-64 py-2 px-3 float-right`}
            type='text'
            disabled={!isAuthAdmin}
            placeholder='Name'
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          />
        </div>
      }
      elements={
        !roles ? (
          <tr>
            <td className='w-full py-16 text-center'>
              <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600' size='4x' />
            </td>
          </tr>
        ) : (
          roles.map((role, index) => <ExpandableRow key={role.name} role={role} deleteRole={deleteRole} isAuthAdmin={isAuthAdmin} bgColor={index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100'} />)
        )
      }
    />
  )
}

export default Roles
