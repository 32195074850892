import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchApi } from 'services/api'
import { Redirect } from 'react-router-dom'

const EmailRedirect = (props) => {
  const searchService = new SearchApi()
  let query = new URLSearchParams(useLocation().search)
  const [uid, setUid] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      let email = query.get('email')
      if (!email) {
        setUid('0')
        return
      }
      let response = await searchService.pagedUserSearch(email, 1, 0)
      if (response.users[0]?.uid != null) {
        setUid(response.users[0]?.uid)
      } else {
        setUid('0')
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='flex flex-row justify-center'>
      <p>Redirecting... {uid}</p>
      {uid === '0' && <Redirect to='/Home' />}
      {uid !== null && uid !== '0' && <Redirect to={'/user/' + uid} />}
    </div>
  )
}

export default EmailRedirect
