import React, { useEffect, useState, useContext } from 'react'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import alertify from 'alertifyjs'
import { AuthContext } from 'services/context/AuthContext'

const GroupsEdit = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [group, setGroup] = useState(null)
  const [newRole, setNewRole] = useState('')
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  useEffect(() => {
    if (props.showModal && props.group) {
      setShowModal(props.showModal)
      authService.getGroup(props.group).then((data) => {
        setGroup(data)
      })
    }
    // eslint-disable-next-line
  }, [props])

  const refreshGroup = () => {
    authService.getGroup(group.name).then((data) => {
      setGroup(data)
    })
  }

  const closeModal = () => {
    props.hideModal(false)
    setShowModal(false)
    setGroup(null)
  }

  const addRole = () => {
    if (group.hasRoles.filter((r) => r.name.toLowerCase() === newRole.toLowerCase()).length > 0) {
      alertify.error('This Group already has that Role')
      return
    }
    authService.addRoleToGroup(newRole, group.name).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Added Role to Group')
        refreshGroup()
      }
    })
  }

  const deleteRole = (roleName) => {
    if (group.hasRoles.filter((r) => r.name.toLowerCase() === roleName.toLowerCase()).length === 0) {
      alertify.error('This Group does not have that Role')
      return
    }
    authService.removeRoleFromGroup(roleName, group.name).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('removed role from group')
        refreshGroup()
      }
    })
  }

  return (
    <div>
      {showModal ? (
        <>
          <div
            className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'
            // onClick={() => closeModal()}
          >
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t'>
                  <h3 className='text-3xl font-semibold'>Edit Group: {!group ? <LoadIndicator className='justify-center' height={40} width={40} /> : group.name}</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr className='border-b border-gray-200'>
                        <th className='px-2 py-2 text-gray-600 text-medium leading-tight text-left'>Roles</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white border-b border-gray-200'>
                      {!group ? (
                        <tr>
                          <td>
                            <LoadIndicator className='justify-center' height={40} width={40} />
                          </td>
                        </tr>
                      ) : (
                        group.hasRoles.map((role) => (
                          <tr key={role.name}>
                            <td className='px-2 py-2'>
                              <div className='truncate'>
                                <div className='text-md leading-5 text-gray-500 truncate'>{role.name}</div>
                              </div>
                            </td>
                            <td className='px-2 py-2'>
                              <button onClick={() => deleteRole(role.name)} className='w-full bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none'>
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                      <tr>
                        <td className='w-10 px-2 py-2 truncate'>
                          <input className='shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight' type='text' placeholder='Name' value={newRole} onChange={(e) => setNewRole(e.target.value)} />
                        </td>
                        <td className='w-10 px-2 py-2 truncate'>
                          <button onClick={addRole} className='w-full bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none'>
                            Add Role
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-center p-6 border-t border-solid border-gray-300 rounded-b'>
                  <button className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1' type='button' style={{ transition: 'all .15s ease' }} onClick={() => closeModal()}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </div>
  )
}

export default GroupsEdit
