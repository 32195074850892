import React from 'react'

const FakeUser = (props) => {
  return (
    <li className='relative col-span-1 bg-white rounded-lg shadow'>
      <div className='w-full flex items-center justify-between p-6 space-x-6'>
        <div className='animate-pulse w-12 h-12 bg-gray-300 rounded-full flex-shrink-0' />
        <div className='flex-1 truncate'>
          <div className='flex items-center space-x-3'>
            <h3 className='animate-pulse text-gray-300 text-md leading-5 font-medium truncate bg-gray-300 w-full'>name</h3>
          </div>
          <p className='animate-pulse mt-1 text-gray-300 bg-gray-300 w-full text-sm leading-5 truncate'>email</p>
        </div>
      </div>
      <div className='w-full flex items-center justify-end px-6 pb-2'>
        <span className='text-gray-300 text-sm pr-2'>Admin</span>
        <span className='bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline'>
          <span aria-hidden='true' className='translate-x-0inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200'></span>
        </span>
      </div>
      <div className='border-t border-gray-100'>
        <div className='-mt-px flex'>
          <div className='w-0 flex-1 flex border-r border-gray-100'>
            <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
              <svg className='w-5 h-5 text-gray-100' viewBox='0 0 20 20' fill='currentColor'>
                <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
                <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
              </svg>
              <span className='ml-3 text-gray-100'>Groups</span>
            </div>
          </div>
          <div className='-ml-px w-0 flex-1 flex'>
            <div className='relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
              <svg className='w-5 h-5 text-gray-100' viewBox='0 0 20 20' fill='currentColor'>
                <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
              </svg>
              <span className='ml-3 text-gray-100'>Token</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default FakeUser
