import React, { useState, useEffect, useContext } from 'react'
import alertify from 'alertifyjs'
import InputBox from 'pages/components/InputBox'
import GroupBox from 'pages/components/GroupBox'
import { AuthContext } from 'services/context/AuthContext'

const QuickCreate = (props) => {
  const [user, setUser] = useState('')
  const [role, setRole] = useState('')
  const [isAuthAdmin, setAuthAdmin] = useState(props.isAuthAdmin)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  useEffect(() => {
    setAuthAdmin(props.isAuthAdmin)
  }, [props])

  const createUser = () => {
    authService.importUsers([user], []).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Created user: ' + user)
      }
    })
  }

  const createGroup = (groupName, groupDesc) => {
    authService.createGroup(groupName, groupDesc).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Created group: ' + groupName + ' with ' + groupDesc)
      }
    })
  }

  const createRole = () => {
    authService.createRole(role, []).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Created Role: ' + role)
      }
    })
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl  border broder-solid border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-4 mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>Quick Create</h3>
          </div>
        </div>
      </div>
      <div className='bg-white'>
        <InputBox placeholder='Email' buttonText='Create User' isAuthAdmin={isAuthAdmin} onClick={createUser} onChange={setUser} />
        <GroupBox groupName='Group Name' groupDesc='Group Description' buttonText='Create Group' isAuthAdmin={isAuthAdmin} createGroup={createGroup} />
        <InputBox placeholder='Role Name' buttonText='Create Role' isAuthAdmin={isAuthAdmin} onClick={createRole} onChange={setRole} />
      </div>
    </>
  )
}

export default QuickCreate
