import React from 'react'
import fire from '../../../../assets/img/PBLogo.png'

const footer = (props) => {
  return (
    <div className='bg-white invisible md:visible z-40'>
      <div className='max-w-screen-xl py-4 px-4 flex justify-between flex-shrink'>
        <div className='mt-8 md:mt-0 md:order-1'>
          <p className='text-center text-base leading-6 text-gray-400 font-medium'>
            <img className='h-8 w-auto' src={fire} alt='Propelled Brands' />
          </p>
        </div>
      </div>
    </div>
  )
}
export const Footer = footer
