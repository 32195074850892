import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Dashboard from 'pages/dashboard/Dashboard'
import Users from 'pages/users/Users'
import Roles from 'pages/roles/Roles'
import Groups from 'pages/groups/Groups'
import ErrorPage from 'pages/error/ErrorPage'
import UnauthorizedPage from 'pages/unauthorized/Unauthorized'
import UserPage from 'pages/user/User'
import GroupPage from 'pages/group/Group'
import EmailRedirect from 'pages/users/EmailRedirect'
import RouteHelper from './RouteHelper'
import { AuthContext } from 'services/context/AuthContext'

const Routes = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const uContext = useContext(AuthContext)

  useEffect(() => {
    let hasAccess = false
    if (uContext.hasAnyGroups(['auth-admin', 'auth-editor'])) {
      hasAccess = true
    }
    setIsAdmin(hasAccess)
  }, [uContext])

  return (
    <>
      {!uContext.inGroups ? (
        <h1>LOADING</h1>
      ) : (
        <Switch>
          {!isAdmin && (
            <>
              <Route component={UnauthorizedPage} />
            </>
          )}

          <Redirect exact from='/login' to='/Home' />
          <Redirect exact from='/' to='/Home' />

          {/* Routes Open To All */}
          <RouteHelper exact path='/home' component={Dashboard} name='Home' display={true} />
          <RouteHelper exact path='/users' component={Users} name='Users' display={true} />
          <RouteHelper exact path='/roles' component={Roles} name='Roles' display={true} />
          <RouteHelper exact path='/groups' component={Groups} name='Groups' display={true} />
          <RouteHelper exact path='/user/:uid' component={UserPage} name='User Page' display={true} />
          <RouteHelper exact path='/group/:group' component={GroupPage} name='Group Page' display={true} />
          <RouteHelper exact path='/email/' component={EmailRedirect} name='Email' display={true} />
          {/* New User or User without roles */}
          <Route component={ErrorPage} />
          {/* <Redirect to="/error"/> */}
        </Switch>
      )}
    </>
  )
}

export default withRouter(Routes)
