/* eslint-disable */
import React, { useEffect, useState, createContext } from 'react'
import { onIdTokenChanged, signOut } from 'firebase/auth'
import { AuthApi } from '../api'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { getDatabase, ref, onValue, serverTimestamp, onDisconnect, set } from 'firebase/database'

export const AuthUserContext = createContext(null)

const withAuthUserProvider = (Component) => (props) => {
  const [groups, setGroups] = useState({ inGroups: null })
  const [roles, setRoles] = useState({ inRoles: null })
  const [admin, setAdmin] = useState({ admin: null })
  const [unsubMonitor, setUnsubMonitor] = useState(null)
  const [unsubListner, setListenerUnSub] = useState(null)
  const [unsubActive, setUnsubActive] = useState(null)
  const [impersonator, setImpersonator] = useState(null)
  const [manualLoginLoad, setManualLoginLoad] = useState(true)
  const [finishedInitialLoad, setFinishedInitialLoad] = useState(false)
  let authApi = new AuthApi(props.user)
  const db = getFirestore(props.app)
  const dbDatabase = getDatabase(props.app)

  useEffect(() => {
    if (props.user) {
      setListenerUnSub(userListener())
      setUnsubMonitor(monitorStatus())
      setUnsubActive(activeMonitor())
    }

    return () => {
      if (unsubMonitor) unsubMonitor()
      if (unsubListner) unsubListner()
      if (unsubActive) unsubActive()
    }
  }, [props.user, props.firebase])

  const monitorStatus = () => {
    if (props.user) {
      const unsub = onSnapshot(doc(db, 'users', props.user.uid), async (doc) => {
        let token = await props.user.getIdToken(true)
        let userInfo = await authApi.getUserInfo(token, props.user.uid)
        if (userInfo) updateGroups(userInfo.inGroups)
      })
      return () => {
        unsub()
      }
    }
  }

  const activeMonitor = () => {
    let isOfflineForDatabase = {
      state: 'offline',
      last_changed: serverTimestamp(),
    }
    let isOnlineForDatabase = {
      state: 'online',
      last_changed: serverTimestamp(),
    }
    if (props.user) {
      let userStatusDatabaseRef = ref(dbDatabase, `authdashboard/status/${props.user.uid}`)

      const infoRef = ref(dbDatabase, `.info/connected`)

      const unsub = onValue(infoRef, (snap) => {
        if (!snap || !snap.val() === false) return

        onDisconnect(userStatusDatabaseRef)
          .set(isOfflineForDatabase)
          .then(() => {
            set(userStatusDatabaseRef, isOnlineForDatabase)
          })
      })

      return () => {
        unsub()
      }
    }
  }

  const userListener = () => {
    const unsubscribe = onIdTokenChanged(props.auth, async (u) => {
      let user = u
      if (user) {
        let token = await user.getIdTokenResult()
        authApi = new AuthApi(user)
        let userInfo = await authApi.getUserInfo(user.uid)
        if (userInfo) {
          updateAdmin(userInfo.admin)
          updateGroups(userInfo.inGroups)
        }

        if (token?.claims?.impersonator) {
          setImpersonator(token?.claims?.impersonator)
        }
      }
      if (!finishedInitialLoad) {
        setFinishedInitialLoad(true)
      }
    })

    return () => {
      unsubscribe()
    }
  }

  const hasGroup = (g) => {
    if (typeof g !== 'string') return false
    if (!groups.inGroups) return false
    return groups.inGroups.find((x) => x.name.toLowerCase() === g.toLowerCase()) ? true : false
  }

  const hasAnyGroups = (g) => {
    if (!Array.isArray(g)) return false
    if (!groups.inGroups) return false
    let hasAnyGroup = false
    g.forEach((item) => {
      if (groups.inGroups.find((x) => x.name.toLowerCase() === item.toLowerCase()) ? true : false) {
        hasAnyGroup = true
      }
    })
    return hasAnyGroup
  }

  const hasAllGroups = (g) => {
    if (!Array.isArray(g)) return false

    if (!groups.inGroups) return false

    g.forEach((item) => {
      if (!(groups.inGroups.find((x) => x.name.toLowerCase() === item.toLowerCase()) ? true : false)) return false
    })
    return true
  }

  const updateGroups = (g) => {
    setGroups({
      inGroups: g,
    })

    let roles = []
    g.forEach((gr) => {
      gr.hasRoles.forEach((r) => {
        roles.push(r.name)
      })
    })

    let uniqueRoles = [...new Set(roles)]
    setRoles({
      inRoles: uniqueRoles,
    })
  }

  const hasRole = (r) => {
    if (typeof r !== 'string') return false
    if (!roles.inRoles) return false
    return roles.inRoles.find((x) => x.toLowerCase() === r.toLowerCase()) ? true : false
  }

  const hasAllRoles = (r) => {
    if (!Array.isArray(r)) return false

    if (!r.inRoles) return false

    r.forEach((name) => {
      if (!(roles.inRoles.find((x) => x.toLowerCase() === name.toLowerCase()) ? true : false)) return false
    })
    return true
  }

  const hasAnyRoles = (r) => {
    if (!Array.isArray(r)) return false

    if (!roles.inRoles) return false

    r.forEach((name) => {
      if (roles.inRoles.find((x) => x.toLowerCase() === name.toLowerCase()) ? true : false) return true
    })
    return false
  }

  const updateAdmin = (a) => {
    setAdmin({
      admin: a,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...groups,
        ...roles,
        ...admin,
        impersonator,
        finishedInitialLoad,
        setFinishedInitialLoad,
        manualLoginLoad,
        setManualLoginLoad,
        hasAnyGroups,
        hasAllGroups,
        hasGroup,
        hasRole,
        hasAnyRoles,
        hasAllRoles,
        user: props.user,
        firebaseApp: props.app,
        AuthApi: authApi,
        logOut: props.logOut,
      }}>
      <Component {...props} />
    </AuthContext.Provider>
  )
}

export default withAuthUserProvider
