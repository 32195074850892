import React, { useEffect, useState, useContext } from 'react'
import { faMinusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import alertify from 'alertifyjs'
import Select from 'react-select'
import { AuthContext } from 'services/context/AuthContext'
import { getFirestore, collection, getDocs } from 'firebase/firestore'

const RolesCard = (props) => {
  const [roles, setRoles] = useState(null)
  const [allRoles, setAllRoles] = useState(null)
  const [selectedRole, setSelectedRole] = useState('')
  const [loading, setLoading] = useState(true)
  const [isAuthAdmin, setIsAuthAdmin] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi
  const db = getFirestore(uContext.firebaseApp)

  const allRoleOptions = allRoles?.map((role) => ({ label: role.name, value: role.name }))

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      width: 'max-content',
      minWidth: '100%',
    }),
    container: (provided) => ({
      ...provided,
      zIndex: 51,
    }),
  }

  useEffect(() => {
    setIsAuthAdmin(uContext.hasGroup('auth-admin'))
  }, [uContext])

  useEffect(() => {
    if (props.group) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [props.group])

  async function fetchData() {
    setLoading(true)
    const data = await getDocs(collection(db, `groups/${props.group}/roles`))
    const g = []
    data.forEach((d) => {
      g.push(d.data())
    })
    setRoles(g.map((r) => r.name))

    const allData = await getDocs(collection(db, `roles`))
    const r = []
    allData.forEach((d) => {
      r.push(d.data())
    })
    setAllRoles(r)

    setLoading(false)
  }

  const deleteRole = (val) => {
    if (roles.filter((r) => r.toLowerCase() === val.toLowerCase()).length === 0) {
      alertify.error('This Group does not have that Role')
      return
    }
    authService.removeRoleFromGroup(val, props.group).then((data) => {
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('removed role from group')
        fetchData()
      }
    })
  }

  const setInputRole = (val) => {
    setSelectedRole(val.value)
  }

  const addRole = () => {
    if (roles.filter((r) => r.toLowerCase() === selectedRole.toLowerCase()).length > 0) {
      alertify.error('This Group already has that Role')
      return
    }
    setLoading(true)
    authService.addRoleToGroup(selectedRole.toLowerCase(), props.group).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Added Role to Group')
        fetchData()
      }
    })
  }

  return (
    <>
      <div className='bg-gray-100 px-4 py-3 rounded-t-lg shadow-2xl border border-gray-200'>
        <div className='-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap'>
          <div className='ml-auto mr-auto mt-2'>
            <h3 className='text-xl leading-6 font-medium text-gray-600'>Roles</h3>
          </div>
        </div>
      </div>
      <div className='bg-white px-2 shadow-xl rounded-b-lg'>
        <div className='opacity-100 w-full flex items-center justify-between p-6 space-x-6'>
          <div className='flex-1 truncate'>
            <div className='flex flex-col space-y-2 font-medium text-md '>
              {!allRoles ? (
                <div className='flex flex-row pr-4'>
                  <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' />
                </div>
              ) : (
                <div className='flex flex-row pr-4'>
                  <div className='h-full w-full bg-gray-100 border border-gray-300 rounded-md mr-2'>
                    <Select placeholder='Roles' menuPosition='fixed' onChange={setInputRole} options={allRoleOptions} menuPlacement='auto' styles={customStyles} />
                  </div>
                  <button onClick={addRole} disabled={loading || !isAuthAdmin} className={`text-white font-bold py-2 px-4 rounded-md focus:outline-none ${isAuthAdmin ? 'bg-blue-600 hover:bg-blue-500' : 'bg-gray-300 cursor-not-allowed opacity-75'}`}>
                    {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' /> : 'Add'}
                  </button>
                </div>
              )}
              {!roles ? (
                <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-500 text-md' />
              ) : (
                roles.map((role, index) => {
                  return (
                    <div key={role} className='flex flex-row pr-4'>
                      <h3 className={(index !== roles.length - 1 ? 'border-b ' : '') + 'w-full border-gray-300 text-gray-500 text-sm leading-5 font-medium truncate'}>{role.toUpperCase()}</h3>
                      <button className={`focus:outline-none text-gray-400 ${!isAuthAdmin ? 'cursor-not-allowed' : ''}`} disabled={!isAuthAdmin} onClick={() => deleteRole(role)}>
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </button>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolesCard
