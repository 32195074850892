import axios from 'axios'

export default class AuthApi {
  constructor(user) {
    this._authBaseUrl = `${process.env.REACT_APP_AUTH_REWRITE_PATH}`
    this._user = user
  }

  async getConfig() {
    let token = await this._user.getIdToken(true)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }

  async getUserInfo(userId) {
    const apiURL = `${this._authBaseUrl}/user/${userId}`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error(`Error retrieving getUserInfo information. error: ${err}`)
      return null
    }
  }

  async getUserInfoByEmail(email) {
    const apiURL = `${this._authBaseUrl}/user?email=${email}`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error('Error retrieving user information by email.')
      return null
    }
  }

  async makeAuthAdmin(uid) {
    //This should be a PATCH to /v1/user/{userid}
    const oldUser = await this.getUser(uid)

    const apiURL = `${this._authBaseUrl}/user/${uid}/admin`

    const patchData = {
      admin: oldUser.admin ? !oldUser.admin : true,
    }

    try {
      const { data } = await axios.patch(apiURL, patchData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async getUser(userId) {
    const apiURL = `${this._authBaseUrl}/user/${userId}`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error('Error retrieving getUser information.')
      return null
    }
  }

  async updateUserFromGWorkspace(uid, access) {
    const apiURL = `${this._authBaseUrl}/user/${uid}/profile`

    try {
      const { data } = await axios.post(apiURL, {}, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async addLocation(uid, location) {
    const apiURL = `${this._authBaseUrl}/user/${uid}/access`
    try {
      const { data } = await axios.post(apiURL, location, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async removeLocation(uid, location) {
    const apiURL = `${this._authBaseUrl}/user/${uid}/access/${location.locationId}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data } //Not sure what to return here for Data if anything.
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async getToken(uid) {
    const apiURL = `${this._authBaseUrl}/user/${uid}/token`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error('Error retrieving getToken information.')
      return null
    }
  }

  async importUsers(users, groups) {
    //This should be a POST to /v1/group/{name}
    const apiURL = `${this._authBaseUrl}/user/import`

    const postData = {
      users,
      groups,
    }

    try {
      const { data } = await axios.post(apiURL, postData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async deleteUser(uid) {
    const apiURL = `${this._authBaseUrl}/user/${uid}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data } //Not sure what to return here for Data if anything.
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async addUserToGroup(uid, groupName) {
    const apiURL = `${this._authBaseUrl}/group/${groupName}/user/${uid}`

    try {
      const { data } = await axios.post(apiURL, {}, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async removeUserFromGroup(uid, groupName) {
    const apiURL = `${this._authBaseUrl}/group/${groupName}/user/${uid}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data } //Not sure what to return here for Data if anything.
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async disableUser(uid, isDisabled) {
    const apiURL = `${this._authBaseUrl}/user/${uid}/disable`

    const patchData = {
      disabled: isDisabled,
    }

    try {
      const { data } = await axios.patch(apiURL, patchData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }
  //#endregion

  //#region  Group

  //DOES NOT APPEAR TO BE BEING USED
  async getGroup(groupName) {
    const apiURL = `${this._authBaseUrl}/group/${groupName}`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error('Error retrieving getGroup information.')
      return null
    }
  }

  //DOES NOT APPEAR TO BE BEING USED
  async getGroups(size, page) {
    const apiURL = `${this._authBaseUrl}/group`

    let config = await this.getConfig()
    config.params = { size: parseInt(size), page: parseInt(page) }

    try {
      const response = await axios.get(apiURL, config)
      return response.data
    } catch (err) {
      console.error('Error retrieving groups.')
      return null
    }
  }

  async createGroup(name, description) {
    const apiURL = `${this._authBaseUrl}/group`

    const postData = {
      name,
      description,
    }

    try {
      const { data } = await axios.post(apiURL, postData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async deleteGroup(name) {
    const apiURL = `${this._authBaseUrl}/group/${name}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data } //Not sure what to return here for Data if anything.
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async updateGroupDesc(name, description) {
    //This should be a PATCH to /v1/group/{name}
    const apiURL = `${this._authBaseUrl}/group/${name}`

    const patchData = {
      description,
    }

    try {
      const { data } = await axios.patch(apiURL, patchData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  //#endregion

  //#region Role
  //DOES NOT APPEAR TO BE BEING USED
  async getRole(roleName) {
    const apiURL = `${this._authBaseUrl}/role/${roleName}`

    try {
      const response = await axios.get(apiURL, await this.getConfig())
      return response.data
    } catch (err) {
      console.error('Error retrieving getRole information.')
      return null
    }
  }

  //DOES NOT APPEAR TO BE BEING USED
  async getRoles(size, page) {
    //This should be a GET to /v1/role
    const apiURL = `${this._authBaseUrl}/role`

    let config = await this.getConfig()
    config.params = { size: parseInt(size), page: parseInt(page) }

    try {
      const response = await axios.get(apiURL, config)
      return response.data
    } catch (err) {
      console.error('Error retrieving roles.')
      return null
    }
  }

  async createRole(name, permissions) {
    //This should be a POST to /v1/role
    const apiURL = `${this._authBaseUrl}/role`

    const postData = {
      name: name,
      permissions: permissions, //Not sure if this is an array of strings which this expects.
      //This also expects inGroups string[] which we dont have here.
    }

    try {
      const { data } = await axios.post(apiURL, postData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async deleteRole(name) {
    const apiURL = `${this._authBaseUrl}/role/${name}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data } //Not sure what to return here for Data if anything.
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async addRoleToGroup(roleName, groupName) {
    const apiURL = `${this._authBaseUrl}/group/${groupName}/role/${roleName}`

    try {
      const { data } = await axios.post(apiURL, {}, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async removeRoleFromGroup(roleName, groupName) {
    const apiURL = `${this._authBaseUrl}/group/${groupName}/role/${roleName}`

    try {
      const { data } = await axios.delete(apiURL, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }

  async updateRole(name, permissions) {
    //This should be a PATCH to /v1/role/{name}
    const apiURL = `${this._authBaseUrl}/role/${name}`

    const patchData = {
      permissions,
    }

    try {
      const { data } = await axios.patch(apiURL, patchData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }
  //#endregion

  async toggleRequireContactSupport(uid) {
    //This should be a PATCH to /v1/user/{userid}
    const oldUser = await this.getUser(uid)

    const apiURL = `${this._authBaseUrl}/user/${uid}`

    const patchData = {
      contactSupport: oldUser.contactSupport ? !oldUser.contactSupport : true,
    }

    try {
      const { data } = await axios.patch(apiURL, patchData, await this.getConfig())
      return { success: true, data }
    } catch (err) {
      return { success: false, error: err.message }
    }
  }
}
