import React, { useEffect, useState, useContext } from 'react'
import alertify from 'alertifyjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlus, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from 'services/context/AuthContext'

const ExpandableRow = (props) => {
  const [role, setRole] = useState(null)
  const [collapsed, setCollapsed] = useState(true)
  const [inputPermission, setInputPermission] = useState('')
  const [loading, setLoading] = useState(false)
  const uContext = useContext(AuthContext)
  const authService = uContext.AuthApi

  const fetchData = async () => {
    let doc = await props.firebase.firestore().collection('roles').doc(props.role.name.toLowerCase()).get()
    let r = doc.data()
    setRole(r)
  }

  useEffect(() => {
    if (props.role) {
      setRole(props.role)
    }
  }, [props.role])

  const addPermission = async () => {
    if (inputPermission === '') {
      alertify.error('There is no permission filled out')
      return
    }
    if (role.permissions.filter((r) => r.toLowerCase() === inputPermission.toLowerCase()).length > 0) {
      alertify.error('This Role already has that Permission')
      return
    }
    setLoading(true)
    role.permissions.push(inputPermission)
    authService.updateRole(role.name, role.permissions).then((data) => {
      setLoading(false)
      if (data.success === false) {
        alertify.error(data.error)
      } else {
        alertify.success('Added Role to Group')
        fetchData()
      }
    })
  }

  const deletePermission = (val) => {
    if (role.permissions.filter((p) => p.toLowerCase() === val.toLowerCase()).length === 0) {
      alertify.error('This Role does not have that Permission')
      return
    }
    setLoading(true)
    authService
      .updateRole(
        role.name,
        role.permissions.filter((p) => p.toLowerCase() !== val.toLowerCase()),
      )
      .then((data) => {
        setLoading(false)
        if (data.success === false) {
          alertify.error(data.error)
        } else {
          alertify.success('removed role from group')
          fetchData()
        }
      })
  }

  return !role ? (
    <tr>
      <td>
        <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600' size='4x' />
      </td>
    </tr>
  ) : (
    [
      <tr key={role.name} className={props.bgColor}>
        <td className='px-2 py-2'>
          <button
            className='focus:outline-none'
            onClick={() => {
              setCollapsed(!collapsed)
            }}>
            {collapsed ? <FontAwesomeIcon icon={faPlus} className='text-blue-500' /> : <FontAwesomeIcon icon={faMinus} className='text-gray-500 text-md' />}
          </button>
        </td>
        <td
          className='px-2 py-2 cursor-pointer'
          role='button'
          onClick={() => {
            setCollapsed(!collapsed)
          }}>
          <p className='text-md leading-5 truncate'>{role.name}</p>
        </td>
        <td className='px-2 py-2 w-full flex justify-end'>
          <button onClick={() => props.deleteRole(role.name)} disabled={!props.isAuthAdmin} className={`focus:outline-none ${!props.isAuthAdmin ? 'cursor-not-allowed' : ''}`}>
            <FontAwesomeIcon icon={faMinusCircle} className='text-red-500 text-xl' />
          </button>
        </td>
      </tr>,
      <tr key={role.name + 'Expanded'} hidden={collapsed} className={props.bgColor + ' border-t'}>
        <td />
        <td className='px-2 py-2 text-gray-600'>
          {loading ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-600 text-sm' />
            </div>
          ) : (
            role.permissions.map((perm, index) => {
              return (
                <div key={index}>
                  <button className='focus:outline-none mr-2' disabled={!props.isAuthAdmin} onClick={() => deletePermission(perm)}>
                    <FontAwesomeIcon icon={faMinusCircle} className='text-gray-400' />
                  </button>
                  <span>{perm}</span>
                </div>
              )
            })
          )}
        </td>
        <td className='px-2 py-2 w-full flex flex-col justify-end border border-gray-300 rounded-md space-y-2 my-2 mr-2'>
          <div className='w-full'>
            <input className='shadow appearance-none w-full border-md rounded-md py-2 px-3 text-gray-700 leading-tight' type='text' placeholder='Permission' value={inputPermission} onChange={(e) => setInputPermission(e.target.value)} />
          </div>
          <button onClick={addPermission} disabled={loading || !props.isAuthAdmin} className={`text-white font-bold py-2 px-4 rounded-md focus:outline-none ${!props.isAuthAdmin ? 'bg-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}>
            {loading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin text-gray-100' /> : 'Add'}
          </button>
        </td>
      </tr>,
    ]
  )
}

export default ExpandableRow
