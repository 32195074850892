import React from 'react'

const PaginatedRows = (props) => {
  return (
    <div className='flex flex-row justify-center'>
      <div className='w-full max-w-6xl py-2'>
        <div className='pb-4 bg-white rounded-md shadow-xl'>
          <div className='flex flex-col'>
            {props.top}
            <div className='px-4 bg-50'>
              <nav className='mt-8 border-t border-gray-400 px-4 flex items-start justify-between sm:px-0'>
                <div onClick={() => props.prevPage()} className='w-0 flex-1 flex cursor-pointer'>
                  <div
                    className={`${
                      props.pageNumber === 0 && 'hidden'
                    } -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150`}>
                    <svg className='mr-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z' clipRule='evenodd' />
                    </svg>
                    Previous
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='hidden md:flex'>
                    <div
                      onClick={() => {
                        props.changePageSize(5)
                      }}
                      className={`${
                        props.pageSize === 5 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                      } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                      5
                    </div>
                    <div
                      onClick={() => {
                        props.changePageSize(10)
                      }}
                      className={`${
                        props.pageSize === 10 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                      } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                      10
                    </div>
                    <div
                      onClick={() => {
                        props.changePageSize(15)
                      }}
                      className={`${
                        props.pageSize === 15 ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-600'
                      } -mt-px border-t-2  pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150 cursor-pointer`}>
                      15
                    </div>
                  </div>
                  <div className='flex justify-center text-gray-500 font-medium pt-3 text-sm'>
                    {props.pageNumber + 1} / {props.totalPages}
                  </div>
                </div>
                <div className='w-0 flex-1 flex justify-end'>
                  <div
                    onClick={() => {
                      props.nextPage()
                    }}
                    className={`${
                      (props.pageNumber + 1 === props.totalPages || props?.rows?.length < props.pageSize) && 'hidden'
                    } cursor-pointer -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150`}>
                    Next
                    <svg className='ml-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z' clipRule='evenodd' />
                    </svg>
                  </div>
                </div>
              </nav>
              <div className='border-gray-200'>{props.elements}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaginatedRows
