import React from 'react'

const InputBox = (props) => {
  return (
    <div className='px-2 py-2 flex'>
      <input
        className={`appearance-none border rounded-l-md w-2/3 py-2 px-3 leading-tight ${!props.isAuthAdmin ? 'placeholder-white bg-gray-300 cursor-not-allowed opacity-75' : 'text-gray-700 bg-gray-100 focus:bg-white focus:outline-none shadow'}`}
        type='text'
        placeholder={props.placeholder}
        value={props.value}
        disabled={!props.isAuthAdmin}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <button
        onClick={props.onClick}
        className={`w-1/3 text-white font-bold py-2 px-4 rounded-r-md focus:outline-none float-center 
        ${!props.isAuthAdmin ? 'bg-gray-300 border border-gray-300 cursor-not-allowed opacity-75' : 'bg-blue-600 hover:bg-blue-500'}`}
        disabled={!props.isAuthAdmin}>
        {props.buttonText}
      </button>
    </div>
  )
}

export default InputBox
