import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import validator from 'validator'

const NewUserList = ({ updateParentEmails }) => {
  const [users, setUsers] = useState([])
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentName, setCurrentName] = useState('')
  const [userLimit, setUserLimit] = useState(false)
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)

  const changeCurrentEmail = (email) => {
    setCurrentEmail(email)
    setIsInvalidEmail(validator.isEmail(email) === false)
  }

  const addEmail = () => {
    let newUsers = [...users, { email: currentEmail, name: currentName }]
    updateEmails(newUsers)
    resetEmail()
  }

  const removeEmail = (user, index) => {
    users.splice(index, 1)

    // const newEmailList = users.filter((e, i) => i === index)
    updateEmails(users)
  }

  const updateEmails = (emailList) => {
    setUsers([...emailList])
    updateParentEmails([...emailList])
    setUserLimit(emailList.length >= 10)
  }

  const resetEmail = () => {
    setCurrentEmail('')
    setCurrentName('')
  }

  return (
    <div>
      <h2 className='mx-3 flex flex-row font-medium text-slate-900'>Emails</h2>
      <hr className='m-3 mt-1 border-1 border-gray-800' />
      <div className='mb-2 px-2 mx-3 flex flex-col'>
        <input
          value={currentEmail}
          disabled={userLimit}
          autoComplete='off'
          className={`${userLimit ? 'cursor-not-allowed ' : ''} pl-2 mb-2 w-full appearance-none bg-transparent py-2 text-gray-500 border-2 border-gray-300 rounded-md text-md leading-5 font-medium focus:outline-none`}
          type='email'
          placeholder='Add Email'
          onChange={(e) => changeCurrentEmail(e.target.value)}
        />
        <input
          required
          value={currentName}
          disabled={userLimit}
          autoComplete='off'
          className={`${userLimit ? 'cursor-not-allowed ' : ''} pl-2 mb-2 w-full appearance-none bg-transparent py-2 text-gray-500 border-2 border-gray-300 rounded-md text-md leading-5 font-medium focus:outline-none`}
          type='name'
          placeholder='Add Name'
          onChange={(e) => setCurrentName(e.target.value)}
        />
        <button onClick={addEmail} disabled={userLimit || isInvalidEmail || !currentName} className={`py-2 px-4 focus:outline-none text-white font-medium text-md rounded-md bg-blue-600 hover:bg-blue-500 cursor-pointer`}>
          Add User
        </button>
      </div>

      <div className={'divide-y'}>
        {users.map((user, index) => (
          <div key={user?.email + user?.name} className='my-1 px-2 mx-3 flex flex-col'>
            <span>Email: {user?.email}</span>
            <span>Name: {user?.name}</span>
            <button className={`ml-2 absolute right-4`} onClick={() => removeEmail(user, index)}>
              <FontAwesomeIcon icon={faMinusCircle} className={'text-red-300 hover:text-red-600'} />
            </button>
          </div>
        ))}
      </div>
      {userLimit ? <span className='mb-2 border-2 border-red-600 rounded-md px-2 mx-3 flex flex-row'>Cannot import more than 10 users at a time</span> : ''}
    </div>
  )
}

export default NewUserList
